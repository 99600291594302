define("ember-svg-jar/inlined/logo-facebook-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Facebook</title><path d=\"M24 23.55c0 .731-.593 1.325-1.325 1.325H16.56v-9.294h3.12l.467-3.623H16.56V9.646c0-1.048.291-1.763 1.795-1.763h1.918v-3.24a25.763 25.763 0 00-2.795-.143c-2.765 0-4.659 1.688-4.659 4.788v2.67H9.692v3.623h3.127v9.294H1.325A1.325 1.325 0 010 23.55V2.2C0 1.468.593.875 1.325.875h21.35C23.407.875 24 1.468 24 2.2v21.35\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "25",
      "viewBox": "0 0 24 25",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});