define("ember-svg-jar/inlined/logo-content-creation-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined-Shape</title><path d=\"M7.691 3a5.229 5.229 0 00-.724 1.5H1.5v12h12v-5.467a5.229 5.229 0 001.5-.724V16.5a1.5 1.5 0 01-1.5 1.5h-12A1.5 1.5 0 010 16.5v-12A1.5 1.5 0 011.5 3h6.191zm3.832 3.477L8.25 5.25l3.273-1.227L12.75.75l1.227 3.273L17.25 5.25l-3.273 1.227L12.75 9.75l-1.227-3.273zm-4.569 4.569L4.5 10.125l2.454-.92.921-2.455.92 2.454 2.455.921-2.454.92-.921 2.455-.92-2.454z\" fill=\"#FFF\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});