define("ember-svg-jar/inlined/logo-youtube-red", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Youtube</title><path d=\"M9.6 12V4.8l6.235 3.6L9.6 12zm13.898-9.377A3.006 3.006 0 0021.377.502C19.505 0 12 0 12 0S4.495 0 2.623.502A3.006 3.006 0 00.502 2.623C0 4.495 0 8.4 0 8.4s0 3.905.502 5.777a3.006 3.006 0 002.121 2.121C4.495 16.8 12 16.8 12 16.8s7.505 0 9.377-.502a3.006 3.006 0 002.121-2.121C24 12.305 24 8.4 24 8.4s0-3.905-.502-5.777z\" fill=\"#FF5151\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "17",
      "viewBox": "0 0 24 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});