define("ember-svg-jar/inlined/icon-youtube-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Post to YouTube Icon</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M16.8 8.625v-5.1l4.417 2.55-4.417 2.55zm9.845-6.642A2.13 2.13 0 0025.142.48C23.816.125 18.5.125 18.5.125s-5.316 0-6.642.355a2.129 2.129 0 00-1.503 1.503C10 3.31 10 6.075 10 6.075s0 2.766.355 4.092a2.129 2.129 0 001.503 1.503c1.326.355 6.642.355 6.642.355s5.316 0 6.642-.355a2.13 2.13 0 001.503-1.503C27 8.84 27 6.075 27 6.075s0-2.766-.355-4.092zM3.278 10l1.414 1.414 4.707-4.707L4.692 2 3.278 3.414l2.293 2.293H0v2h5.571z\"/></g>",
    "attrs": {
      "width": "27",
      "height": "13",
      "viewBox": "0 0 27 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});