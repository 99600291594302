define("ember-svg-jar/inlined/loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g><circle cx=\"16\" cy=\"64\" r=\"16\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#555\" fill-opacity=\".67\" transform=\"rotate(45 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#949494\" fill-opacity=\".42\" transform=\"rotate(90 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#ccc\" fill-opacity=\".2\" transform=\"rotate(135 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#e1e1e1\" fill-opacity=\".12\" transform=\"rotate(180 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#e1e1e1\" fill-opacity=\".12\" transform=\"rotate(225 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#e1e1e1\" fill-opacity=\".12\" transform=\"rotate(270 64 64)\"/><circle cx=\"16\" cy=\"64\" r=\"16\" fill=\"#e1e1e1\" fill-opacity=\".12\" transform=\"rotate(315 64 64)\"/><animateTransform attributeName=\"transform\" type=\"rotate\" values=\"0 64 64;315 64 64;270 64 64;225 64 64;180 64 64;135 64 64;90 64 64;45 64 64\" calcMode=\"discrete\" dur=\"720ms\" repeatCount=\"indefinite\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "version": "1.0",
      "width": "64",
      "height": "64",
      "viewBox": "0 0 128 128"
    }
  };
  _exports.default = _default;
});