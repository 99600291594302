define("ember-svg-jar/inlined/icon-help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:none;stroke:#404040;stroke-width:2}</style><title>857C0F01-1868-4F07-891C-E428351CBD43</title><g id=\"Assets\"><g id=\"help-gray\" transform=\"translate(49 266)\"><g id=\"Group-4\" transform=\"translate(5.5 3.774)\"><circle id=\"Oval-3\" cx=\"-44.5\" cy=\"-255.7\" fill=\"#404040\" r=\".9\"/><path id=\"Oval-2\" class=\"st1\" d=\"M-44.5-259.1c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.3-2.4-2.3-2.4 1-2.4 2.3\"/><path id=\"Line\" d=\"M-44.5-259.1v.9\" fill=\"none\" stroke=\"#404040\" stroke-width=\"2\" stroke-linecap=\"square\"/></g><circle id=\"Oval-6\" class=\"st1\" cx=\"-39\" cy=\"-256\" r=\"8\"/></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});