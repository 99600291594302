define("ember-svg-jar/inlined/checklist-complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M35.752 39.167H12.25V7.834h15.666v7.833h7.834l.002 23.5zm-5.067-35.25H12.25a3.922 3.922 0 00-3.917 3.917v31.333a3.92 3.92 0 003.917 3.917h23.5a3.92 3.92 0 003.916-3.917v-26.27l-8.98-8.98z\" fill=\"#2C2D30\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M27.916 21.542h-11.75v3.917h11.75v-3.917zM16.166 33.292h15.667v-3.917H16.166v3.917z\" fill=\"#2C2D30\"/><circle cx=\"37\" cy=\"36.5\" r=\"12.5\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M37 28.318c-4.511 0-8.182 3.67-8.182 8.182 0 4.511 3.67 8.182 8.182 8.182 4.511 0 8.182-3.671 8.182-8.182 0-4.512-3.671-8.182-8.182-8.182zm0 18.409c-5.64 0-10.227-4.588-10.227-10.227 0-5.64 4.587-10.227 10.227-10.227 5.64 0 10.227 4.587 10.227 10.227 0 5.64-4.588 10.227-10.227 10.227z\" fill=\"#29C893\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M36.631 42.181l-4.177-4.283 1.826-1.873 2.022 2.073 4.278-6.144 2.101 1.538-6.05 8.69z\" fill=\"#29C893\"/>",
    "attrs": {
      "width": "55",
      "height": "55",
      "viewBox": "0 0 55 55",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});