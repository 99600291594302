define("ember-svg-jar/inlined/icon-location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/Instagram</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M7.749 6.5c-.827 0-1.5-.673-1.5-1.5 0-.826.673-1.5 1.5-1.5s1.5.674 1.5 1.5c0 .827-.673 1.5-1.5 1.5M7.749 15.5c-3.612 0-7.5-.938-7.5-3 0-.716.486-1.713 2.8-2.4l.427 1.438c-1.427.423-1.727.889-1.727.962 0 .386 2.063 1.5 6 1.5 3.938 0 6-1.114 6-1.5 0-.073-.298-.537-1.717-.96l.428-1.437c2.305.686 2.789 1.682 2.789 2.397 0 2.062-3.888 3-7.5 3\"/><path d=\"M7.749 2c-1.654 0-3 1.345-3 3 0 1.873 1.845 4.348 3 5.652 1.156-1.303 3-3.778 3-5.652 0-1.655-1.345-3-3-3zm0 10.811l-.53-.53c-.162-.163-3.97-4.007-3.97-7.281 0-2.481 2.018-4.5 4.5-4.5s4.5 2.019 4.5 4.5c0 3.274-3.808 7.118-3.97 7.28l-.53.531z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});