define("pendo/services/pendo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    _previouslyInitialized: false,
    initialize: function initialize(opts) {
      // we want to call pendo.initialize only once per session.
      if (Ember.get(this, '_previouslyInitialized')) {
        return;
      }

      Ember.set(this, '_previouslyInitialized', true);
      window.pendo && window.pendo.initialize(opts);
    }
  });

  _exports.default = _default;
});