define("ember-svg-jar/inlined/icon-arrow-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icons/arrow</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"11\" cy=\"11\" r=\"11\"/><path fill=\"#3894FD\" d=\"M11.724 7L10.44 8.203l2.086 1.948H2v1.7h10.519l-2.08 1.95L11.727 15 16 10.993z\"/><path d=\"M10.788 2C6.67 2 3.178 4.653 2 8.3h1.96c1.097-2.634 3.742-4.5 6.828-4.5 4.063 0 7.37 3.23 7.37 7.2s-3.307 7.2-7.37 7.2c-3.086 0-5.731-1.865-6.828-4.5H2c1.177 3.647 4.67 6.3 8.788 6.3C15.868 20 20 15.963 20 11s-4.133-9-9.212-9\" fill=\"#3894FD\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});