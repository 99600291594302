define('ember-poppays/components/before-after', ['exports', 'ember-poppays/helpers/number-abbreviation', 'ember-poppays/templates/components/before-after'], function (exports, _numberAbbreviation, _beforeAfter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _beforeAfter.default,
    classNames: ['before-after'],
    attributeBindings: ['data-test-before-after'],
    'data-test-before-after': true,

    previousValue: null,
    value: null,
    labels: null,
    isCurrency: false,

    previousAndCurrentDifferent: Ember.computed('previousValue', 'value', function () {
      return this.previousValue != null && this.value != null && this.previousValue !== this.value;
    }),

    formattedPreviousValue: Ember.computed('previousValue', 'isCurrency', function () {
      if (this.isCurrency) {
        return (0, _numberAbbreviation.numberAbbreviation)([this.previousValue, "$0,0.00"]);
      }

      return this.previousValue;
    }),

    formattedValue: Ember.computed('value', 'isCurrency', function () {
      if (this.isCurrency) {
        return (0, _numberAbbreviation.numberAbbreviation)([this.value, "$0,0.00"]);
      }

      return this.value;
    })
  });
});