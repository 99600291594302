define("ember-svg-jar/inlined/icon-mixed-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>67979786-50C8-4283-A577-495E02488120</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-1-.997h20.083v20.083H-1z\"/><g fill=\"#404040\" fill-rule=\"nonzero\"><path d=\"M6.531 8.206H2.347c-.924 0-1.673-.751-1.673-1.674V2.348c0-.922.75-1.674 1.673-1.674h4.184c.924 0 1.674.752 1.674 1.674v4.184c0 .923-.75 1.674-1.674 1.674zM2.347 2.348v4.184H6.53l.002-4.184H2.347zM15.736 8.206h-4.184c-.924 0-1.674-.751-1.674-1.674V2.348c0-.922.75-1.674 1.674-1.674h4.184c.924 0 1.674.752 1.674 1.674v4.184c0 .923-.75 1.674-1.674 1.674zm-4.184-5.858v4.184h4.181l.003-4.184h-4.184zM6.531 17.41H2.347c-.924 0-1.673-.75-1.673-1.673v-4.184c0-.922.75-1.674 1.673-1.674h4.184c.924 0 1.674.752 1.674 1.674v4.184c0 .923-.75 1.673-1.674 1.673zm-4.184-5.857v4.184H6.53l.002-4.184H2.347zM15.736 17.41h-4.184c-.924 0-1.674-.75-1.674-1.673v-4.184c0-.922.75-1.674 1.674-1.674h4.184c.924 0 1.674.752 1.674 1.674v4.184c0 .923-.75 1.673-1.674 1.673zm-4.184-5.857v4.184h4.181l.003-4.184h-4.184z\"/></g></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});