define("ember-svg-jar/inlined/icon-coupon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>8DCC6C0D-376D-468B-BA19-CD114CB473CF</title><g fill=\"#3894FD\" fill-rule=\"evenodd\"><path d=\"M22 12.873V16h-4v-2h-2v2H2v-3.127A4.008 4.008 0 005 9a4.008 4.008 0 00-3-3.873V2h14v2h2V2h4v3.127a4.008 4.008 0 00-3 3.872 4.008 4.008 0 003 3.874M23 7a1 1 0 001-1V1c0-.551-.448-1-1-1H1C.448 0 0 .449 0 1v5a1 1 0 001 1c1.103 0 2 .897 2 2 0 1.102-.897 2-2 2-.552 0-1 .448-1 1v5a1 1 0 001 1h22a1 1 0 001-1v-5c0-.552-.448-1-1-1-1.103 0-2-.898-2-2 0-1.103.897-2 2-2\"/><path d=\"M16 8h2V6h-2zM16 12h2v-2h-2zM13.293 4.293l-7 7 1.414 1.414 7-7zM8 8a2 2 0 10.001-3.999A2 2 0 008 8M13 9a2 2 0 10.001 4.001A2 2 0 0013 9\"/></g>",
    "attrs": {
      "width": "24",
      "height": "18",
      "viewBox": "0 0 24 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});