define("ember-svg-jar/inlined/icon-great", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-great</title><path fill=\"#07c98f\" d=\"M9.4 21l-6.1-6.1 2.2-2.2 3.8 3.8L20.7 4 23 6.1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 25 25"
    }
  };
  _exports.default = _default;
});