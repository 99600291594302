define("ember-svg-jar/inlined/icon-thumby", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>9D292576-E239-420F-A904-6266BE92617B</title><defs><path id=\"a\" d=\"M57.349.711H0V65H57.35V.71z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(.5 -.71)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M57.349 36.842a7.635 7.635 0 00-2.358-5.508 7.635 7.635 0 002.358-5.508c0-4.214-3.447-7.661-7.661-7.661H26.383c-.164 0-.326.015-.488.025l2.54-9.441C28.86 4.268 24.798.703 20.37.71c-4.43.007-6.735 2.93-8.04 8.065L7.838 25.48a27.206 27.206 0 00-.976 3.134L.164 54.55C-1.067 60.983 4.866 65 10.613 65h12.93c.63 0 1.242-.066 1.84-.173.47.109.956.173 1.457.173h20.267c3.543 0 6.443-2.9 6.443-6.443a6.404 6.404 0 00-1.628-4.26c2.208-1.024 3.753-3.26 3.753-5.843a6.431 6.431 0 00-2.29-4.911c2.358-1.31 3.964-3.826 3.964-6.701\" fill=\"#07C98F\" mask=\"url(#b)\"/></g><path d=\"M48.562 36.55S40.146 47.86 29.765 47.86c-10.382 0-18.797-11.312-18.797-11.312s8.415-11.312 18.797-11.312c10.381 0 18.797 11.312 18.797 11.312\" fill=\"#FFF\"/><path d=\"M34.308 36.55a4.543 4.543 0 11-9.086-.001 4.543 4.543 0 019.086 0\" fill=\"#231F1F\"/></g>",
    "attrs": {
      "width": "58",
      "height": "65",
      "viewBox": "0 0 58 65",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});