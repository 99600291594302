define("@popularpays/ember-design-system/helpers/design-system-token", ["exports", "lodash", "@popularpays/ember-design-system/addon/styles/design-system/_js_object.js"], function (_exports, _lodash, _js_object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.designSystemToken = designSystemToken;
  _exports.default = void 0;

  // TODO: fix this import so we can use this helper in .hbs files and this function in .js files
  function designSystemToken(tokenPath) {
    return (0, _lodash.get)(_js_object.default, tokenPath);
  }

  var _default = Ember.Helper.helper(function _designSystemToken(positional
  /* , named */
  ) {
    return designSystemToken(positional[0]);
  });

  _exports.default = _default;
});