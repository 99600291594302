define("ember-svg-jar/inlined/close-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<rect width=\"35\" height=\"35\" x=\".5\" y=\".5\" stroke=\"#000\" stroke-opacity=\".08\" rx=\"17.5\"/><path stroke=\"#000\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" stroke-width=\"1.6\" d=\"M22 14l-8 8m0-8l8 8\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "36",
      "height": "36",
      "fill": "none"
    }
  };
  _exports.default = _default;
});