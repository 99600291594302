define("ember-svg-jar/inlined/icon-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/Instagram</title><g fill-rule=\"nonzero\" fill=\"#404040\"><path d=\"M7.932 7.936A3.719 3.719 0 014.217 4.22 3.72 3.72 0 017.932.506a3.72 3.72 0 013.715 3.715 3.719 3.719 0 01-3.715 3.715zm0-5.573a1.859 1.859 0 00-1.857 1.858 1.86 1.86 0 001.857 1.857A1.86 1.86 0 009.79 4.221a1.859 1.859 0 00-1.858-1.858zM15.363 15.366H.502v-.929c0-3.281 3.056-5.572 7.43-5.572 4.375 0 7.43 2.291 7.43 5.572v.93zM2.507 13.51h10.851c-.555-1.68-2.631-2.787-5.425-2.787s-4.87 1.107-5.426 2.787z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});