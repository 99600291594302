define("ember-svg-jar/inlined/icon-tiktok-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Group</title><g fill=\"#404040\" fill-rule=\"nonzero\"><path d=\"M3.278 12l1.414 1.414 4.707-4.707L4.692 4 3.278 5.414l2.293 2.293H0v2h5.571zM23.022 3.636c.754.372 1.49.485 1.853.446v2.899s-1.035-.042-1.803-.244c-1.07-.284-1.757-.72-1.757-.72s-.476-.312-.512-.333v5.989c0 .333-.089 1.166-.355 1.86-.349.908-.89 1.506-.989 1.627 0 0-.658.808-1.813 1.35-1.042.49-1.96.478-2.234.49 0 0-1.582.063-3.008-.897l-.007-.008a5.992 5.992 0 01-.423-.478 5.787 5.787 0 01-.804-1.453v-.003c-.109-.323-.329-1.083-.32-1.835l.003-.093c-.032.787.207 1.598.317 1.935v.003c.071.192.348.876.804 1.453.131.167.273.326.423.479a5.982 5.982 0 01-1.277-1.191 5.671 5.671 0 01-.8-1.446v-.007c-.114-.337-.352-1.152-.316-1.938.056-1.386.526-2.24.65-2.453a5.738 5.738 0 011.263-1.541 5.302 5.302 0 011.5-.886 5.269 5.269 0 011.06-.312 5.304 5.304 0 011.657-.025v.66l-.003-.001V9.26s-1.17-.387-2.11.297c-.653.507-1.002 1-1.105 1.882-.004.649.156 1.566 1.031 2.18.103.067.203.127.303.18.152.206.337.386.55.535.253.166.493.286.729.364l-.14-.051a3.286 3.286 0 01-.585-.313 2.307 2.307 0 01-.55-.531c.728.386 1.379.379 2.186.056.612-.244 1.07-.797 1.284-1.406.116-.328.13-.657.131-.994V0h2.948c-.003 0-.035.28.04.712h-.004c.089.521.33 1.265.978 1.999.26.276.551.524.867.73.11.07.22.135.338.195z\"/></g>",
    "attrs": {
      "width": "27",
      "height": "17",
      "viewBox": "0 0 27 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});