define("ember-svg-jar/inlined/icon-send-green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Status/Overlay/Submit</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"11\" cy=\"11\" r=\"11\"/><path d=\"M13.337 7.657L5.354 12.73l2.763.753 5.22-5.826zm2.523.179l-5.586 6.234 5.016 1.367.57-7.601zm-2.765 9.162l-3.796 3.268a1 1 0 01-1.653-.757v-4.03L2.86 14.142a1.154 1.154 0 01-.814-1.43 1.16 1.16 0 01.482-.646l13.63-8.873a1.203 1.203 0 011.643.321c.146.214.215.469.196.725l-.909 12.422c-.046.637-.613 1.117-1.266 1.072a1.21 1.21 0 01-.241-.042l-2.486-.694zm-1.873-.522l-1.576-.44v2l1.576-1.56z\" fill=\"#07C98F\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});