define("ember-svg-jar/inlined/icon-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>5EC73644-418C-46DB-BD89-8B15E53AC66F</title><path d=\"M2.98 14.476h6.972c3.991 0 7.238-2.84 7.238-6.333 0-3.493-3.247-6.333-7.238-6.333-3.99 0-7.238 2.84-7.238 6.333 0 1.39.514 2.714 1.486 3.829a.905.905 0 01-.02 1.21l-1.2 1.294zm6.972 1.81H.905a.906.906 0 01-.663-1.52l2.086-2.248C1.394 11.212.905 9.713.905 8.143.905 3.653 4.964 0 9.952 0 14.942 0 19 3.653 19 8.143s-4.059 8.143-9.048 8.143z\" fill=\"#757575\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "19",
      "height": "17",
      "viewBox": "0 0 19 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});