define("ember-svg-jar/inlined/icon-back-arrow", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.994 8.569a.799.799 0 00.194.456l6.4 7.4a.8.8 0 101.206-1.05L2.85 8.5l5.944-6.875A.8.8 0 107.588.575l-6.4 7.4a.8.8 0 00-.194.594z\" fill=\"#000\"/>",
    "attrs": {
      "width": "10",
      "height": "17",
      "viewBox": "0 0 10 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});