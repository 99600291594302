define("ember-svg-jar/inlined/icon-continue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 1.385L1.313 0 7 6l-5.688 6L0 10.615 4.375 6 0 1.385z\" fill=\"#404040\"/>",
    "attrs": {
      "width": "7",
      "height": "12",
      "viewBox": "0 0 7 12",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});