define("ember-svg-jar/inlined/icon-pencil-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 2151</title><path d=\"M13.056 1.945c.573.574.573 1.575 0 2.148l-.54.54-2.148-2.148.54-.54c.574-.573 1.574-.573 2.148 0zM1.782 13.22l.446-1.78 1.334 1.335-1.78.445zm7.526-9.673l2.147 2.147-6.426 6.426L2.882 9.97l6.426-6.425zM14.115.884A2.997 2.997 0 0011.98 0c-.806 0-1.564.313-2.134.884L1.289 9.44l-.001.003a.945.945 0 00-.196.345l-1.07 4.279a.748.748 0 00.198.712l.002.001a.75.75 0 00.712.198l4.278-1.07a.939.939 0 00.348-.197l8.557-8.557c.57-.57.884-1.328.883-2.134a3 3 0 00-.883-2.135h-.002z\" fill=\"#fff\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});