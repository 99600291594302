define("ember-svg-jar/inlined/icon-logout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.46 6.548l-.944.945 1.532 1.531h-5.75v1.336h5.75l-1.532 1.532.945.944 3.144-3.144-3.144-3.144z\" fill=\"#404040\"/><path d=\"M3.846 15.154V4.23h6.923v1.994h1.539V4.159c0-.803-.622-1.467-1.42-1.467H3.728c-.794 0-1.42.662-1.42 1.457v11.086c0 .796.626 1.457 1.42 1.457h7.162c.793 0 1.419-.66 1.419-1.454v-2.089h-1.539v2.005H3.846z\" fill=\"#404040\"/>",
    "attrs": {
      "width": "25",
      "height": "30",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});