define("@popularpays/ember-design-system/components/poppays-radio-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <RadioButton
    @value={{@value}}
    @groupValue={{@groupValue}}
    @classNames="poppays-radio-button {{@classNames}}"
    @changed={{@changed}}
    @radioClass={{@radioClass}}
    @ariaDescribedby={{@ariaDescribedby}}
    @ariaLabelledby={{@ariaLabelledby}}
    @autofocus={{@ariaDescribedby}}
    checkedClass={{@checkedClass}}
    disabled={{@disabled}}
    name={{@name}}
    radioId={{@radioId}}
    required={{@required}}
    tabindex={{@tabindex}}
  >
    {{yield}}
  </RadioButton>
  
  
  
  */
  {
    "id": "LaTohelb",
    "block": "[[[8,[39,0],[[16,\"checkedClass\",[30,1]],[16,\"disabled\",[30,2]],[16,3,[30,3]],[16,\"radioId\",[30,4]],[16,\"required\",[30,5]],[16,\"tabindex\",[30,6]]],[[\"@value\",\"@groupValue\",\"@classNames\",\"@changed\",\"@radioClass\",\"@ariaDescribedby\",\"@ariaLabelledby\",\"@autofocus\"],[[30,7],[30,8],[29,[\"poppays-radio-button \",[30,9]]],[30,10],[30,11],[30,12],[30,13],[30,12]]],[[\"default\"],[[[[1,\"\\n  \"],[18,14,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\\n\\n\"]],[\"@checkedClass\",\"@disabled\",\"@name\",\"@radioId\",\"@required\",\"@tabindex\",\"@value\",\"@groupValue\",\"@classNames\",\"@changed\",\"@radioClass\",\"@ariaDescribedby\",\"@ariaLabelledby\",\"&default\"],false,[\"radio-button\",\"yield\"]]",
    "moduleName": "@popularpays/ember-design-system/components/poppays-radio-button.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});