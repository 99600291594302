define("ember-svg-jar/inlined/icon-triangle-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>5FEED662-BAA0-4FE1-A071-210239CF8958</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M.998 14.26C.447 15.22.893 16 1.995 16h14.01c1.102 0 1.549-.779.997-1.74L9.998 2.033c-.551-.961-1.444-.962-1.996 0L.998 14.259z\" stroke=\"#FF5151\" fill=\"#FF5151\"/><path d=\"M9 6v4\" stroke=\"#FFF\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/><path d=\"M10 12.986a.994.994 0 01-1 .987c-.552 0-1-.442-1-.987A.994.994 0 019 12c.552 0 1 .442 1 .986\" fill=\"#FFF\"/></g>",
    "attrs": {
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});