define("ember-svg-jar/inlined/icon-video-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>322F5480-D7FE-42B2-ABFF-B9A6E4448DF2</title><path d=\"M21.787 10.9V1.823l9.077 9.077h-9.077zM9.08 30.87V16.346l14.524 7.267L9.079 30.87zM3.633.007A3.635 3.635 0 00.002 3.638v32.678a3.635 3.635 0 003.63 3.631H29.05a3.635 3.635 0 003.63-3.63V8.332L24.355.007H3.633z\" fill=\"#974FEF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "33",
      "height": "40",
      "viewBox": "0 0 33 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});