define("ember-svg-jar/inlined/icon-campaigns-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>F84C2B1E-111C-411E-AC28-64F2C599A0BD</title><path d=\"M10.875 22.5a1.124 1.124 0 01-1.125-1.125V13.5H4.124a1.127 1.127 0 01-.877-1.828l9-11.25a1.124 1.124 0 012.003.703V9h5.625c.433 0 .827.248 1.014.638.187.389.134.851-.136 1.19l-9 11.25a1.123 1.123 0 01-.878.422z\" fill=\"#974FEF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});