define("ember-svg-jar/inlined/icon-error-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Status Error</title><g fill=\"#a5a5a5\" fill-rule=\"evenodd\"><path d=\"M10 2c-4.411 0-8 3.589-8 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8m0 18C4.486 20 0 15.515 0 10 0 4.486 4.486 0 10 0s10 4.486 10 10c0 5.515-4.486 10-10 10\"/><path d=\"M11 11H9V4h2zM11.25 14a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});