define("ember-svg-jar/inlined/icon-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>3995BF7D-280E-49C5-9B3C-EBE1C04B221A</title><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M19 0a3 3 0 013 3c0 1.657-3 5.922-3 5.922S16 4.657 16 3a3 3 0 013-3z\" stroke=\"#4CC8EF\" stroke-width=\"2\"/><circle fill=\"#4CC8EF\" cx=\"19\" cy=\"3\" r=\"1\"/><path d=\"M.964 6.242v13.875l6.002-4.277V1.895L.964 6.242zm12 0v13.875l6.002-4.277v-2.519m-6-7.08v13.876L6.964 15.84V1.895l6.002 4.347z\" stroke=\"#4CC8EF\" stroke-width=\"2\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "width": "24",
      "height": "23",
      "viewBox": "0 0 24 23",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});