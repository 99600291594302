define("ember-svg-jar/inlined/icon-redo-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>65FD8151-54A8-4843-A1AF-D2EE429177C0</title><defs><filter x=\"-11.2%\" y=\"-11.2%\" width=\"122.5%\" height=\"122.5%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feOffset in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\".75\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g filter=\"url(#a)\" transform=\"translate(2 2)\" fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M10 0a9.93 9.93 0 00-7.062 2.938L1 1v6h6L4.351 4.351A7.939 7.939 0 0110 2c4.411 0 8 3.589 8 8s-3.589 8-8 8-8-3.589-8-8H0c0 5.514 4.486 10 10 10s10-4.486 10-10S15.514 0 10 0\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});