define("@popularpays/ember-design-system/lib/extract-color-palette-from-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  //  Convert each pixel value ( number ) to hexadecimal ( string ) with base 16
  var rgbToHex = function rgbToHex(pixel) {
    var componentToHex = function componentToHex(c) {
      var hex = c.toString(16);
      return hex.length === 1 ? "0" + hex : hex;
    };

    return ("#" + componentToHex(pixel.r) + componentToHex(pixel.g) + componentToHex(pixel.b)).toUpperCase();
  };

  var buildRgb = function buildRgb(imageData) {
    var rgbValues = []; // note that we are loopin every 4!
    // for every Red, Green, Blue and Alpha

    for (var i = 0; i < imageData.length; i += 4) {
      var rgb = {
        r: imageData[i],
        g: imageData[i + 1],
        b: imageData[i + 2]
      };
      rgbValues.push(rgb);
    }

    return rgbValues;
  }; // returns what color channel has the biggest difference


  var findBiggestColorRange = function findBiggestColorRange(rgbValues) {
    /**
     * Min is initialized to the maximum value posible
     * from there we procced to find the minimum value for that color channel
     *
     * Max is initialized to the minimum value posible
     * from there we procced to fin the maximum value for that color channel
     */
    var rMin = Number.MAX_VALUE;
    var gMin = Number.MAX_VALUE;
    var bMin = Number.MAX_VALUE;
    var rMax = Number.MIN_VALUE;
    var gMax = Number.MIN_VALUE;
    var bMax = Number.MIN_VALUE;
    rgbValues.forEach(function (pixel) {
      rMin = Math.min(rMin, pixel.r);
      gMin = Math.min(gMin, pixel.g);
      bMin = Math.min(bMin, pixel.b);
      rMax = Math.max(rMax, pixel.r);
      gMax = Math.max(gMax, pixel.g);
      bMax = Math.max(bMax, pixel.b);
    });
    var rRange = rMax - rMin;
    var gRange = gMax - gMin;
    var bRange = bMax - bMin; // determine which color has the biggest difference

    var biggestRange = Math.max(rRange, gRange, bRange);

    if (biggestRange === rRange) {
      return "r";
    }

    if (biggestRange === gRange) {
      return "g";
    }

    return "b";
  };
  /**
   * Median cut implementation
   * can be found here -> https://en.wikipedia.org/wiki/Median_cut
   */


  var quantization = function quantization(rgbValues, depth) {
    var MAX_DEPTH = 4; // Base case

    if (depth === MAX_DEPTH || rgbValues.length === 0) {
      var color = rgbValues.reduce(function (prev, curr) {
        prev.r += curr.r;
        prev.g += curr.g;
        prev.b += curr.b;
        return prev;
      }, {
        r: 0,
        g: 0,
        b: 0
      });
      color.r = Math.round(color.r / rgbValues.length);
      color.g = Math.round(color.g / rgbValues.length);
      color.b = Math.round(color.b / rgbValues.length);
      return [color];
    }
    /**
     *  Recursively do the following:
     *  1. Find the pixel channel (red,green or blue) with biggest difference/range
     *  2. Order by this channel
     *  3. Divide in half the rgb colors list
     *  4. Repeat process again, until desired depth or base case
     */


    var componentToSortBy = findBiggestColorRange(rgbValues);
    rgbValues.sort(function (p1, p2) {
      return p1[componentToSortBy] - p2[componentToSortBy];
    });
    var mid = rgbValues.length / 2;
    return [].concat(_toConsumableArray(quantization(rgbValues.slice(0, mid), depth + 1)), _toConsumableArray(quantization(rgbValues.slice(mid + 1), depth + 1)));
  };

  var extractColorPaletteFromImage = function extractColorPaletteFromImage(imageUrl) {
    return new Promise(function (res) {
      var image = new Image();
      image.addEventListener('load', function () {
        // Whenever file & image is loaded proceed to extract the information from the image
        // Set the canvas size to be the same as of the uploaded image
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0);
        /**
         * getImageData returns an array full of RGBA values
         * each pixel consists of four values: the red value of the colour, the green, the blue and the alpha
         * (transparency). For array value consistency reasons,
         * the alpha is not from 0 to 1 like it is in the RGBA of CSS, but from 0 to 255.
         */

        var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height); // Convert the image data to RGB values so its much simpler

        var rgbArray = buildRgb(imageData.data);
        /**
         * Color quantization
         * A process that reduces the number of colors used in an image
         * while trying to visually maintin the original image as much as possible
         */

        var quantColors = quantization(rgbArray, 0);
        var hexColors = quantColors.map(function (color) {
          return rgbToHex(color);
        });
        res(hexColors);
      });
      var sourceUrl = imageUrl;
      var googleProxyURL = 'https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=';
      image.crossOrigin = 'Anonymous';
      image.src = googleProxyURL + encodeURIComponent(sourceUrl);
    });
  };

  var _default = extractColorPaletteFromImage;
  _exports.default = _default;
});