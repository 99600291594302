define("ember-svg-jar/inlined/status-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M27 9C17.075 9 9 17.075 9 27s8.075 18 18 18 18-8.075 18-18S36.925 9 27 9zm0 40.5C14.594 49.5 4.5 39.409 4.5 27 4.5 14.594 14.594 4.5 27 4.5c12.407 0 22.5 10.094 22.5 22.5 0 12.409-10.093 22.5-22.5 22.5z\" fill=\"#FF3B30\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M29.25 29.25h-4.5V13.5h4.5v15.75zM29.813 36a2.814 2.814 0 01-5.625 0 2.814 2.814 0 015.625 0z\" fill=\"#FF3B30\"/>",
    "attrs": {
      "width": "54",
      "height": "54",
      "viewBox": "0 0 54 54",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});