define("ember-svg-jar/inlined/icon-facebook-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/Facebook Post To</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M25.875 18.171a.704.704 0 01-.704.704h-3.249v-4.937h1.658l.248-1.925h-1.906v-1.228c0-.557.155-.937.954-.937h1.019V8.127a13.686 13.686 0 00-1.485-.076c-1.469 0-2.475.897-2.475 2.543v1.42h-1.661v1.924h1.661v4.937H13.83a.704.704 0 01-.704-.704V6.83c0-.389.315-.704.704-.704H25.17c.389 0 .704.315.704.704v11.342M4.139 15.648L5.493 17 10 12.5 5.493 8 4.139 9.352l2.195 2.192H1v1.912h5.334z\"/></g>",
    "attrs": {
      "width": "28",
      "height": "24",
      "viewBox": "0 0 28 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});