define("ember-svg-jar/inlined/collaborations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.376 3.376A6.478 6.478 0 018 1.455 6.553 6.553 0 0114.546 8H16c0-4.41-3.589-8-8-8A7.92 7.92 0 002.35 2.348L.728.728V5.09H5.09L3.376 3.376zM12.623 12.624A6.474 6.474 0 018 14.545 6.553 6.553 0 011.455 8H0c0 4.41 3.59 8 8 8 2.158 0 4.16-.849 5.652-2.348l1.62 1.62V10.91H10.91l1.714 1.715z\" fill=\"#2C2D30\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 10.91A2.912 2.912 0 015.09 8 2.912 2.912 0 018 5.09 2.912 2.912 0 0110.909 8a2.912 2.912 0 01-2.91 2.91zm0-1.455a1.456 1.456 0 000-2.91 1.456 1.456 0 000 2.91z\" fill=\"#2C2D30\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});