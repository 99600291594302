define("ember-svg-jar/inlined/add-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M6.5 5.5a2 2 0 100-4 2 2 0 000 4zm0 1.5a3.5 3.5 0 100-7 3.5 3.5 0 000 7zM11.27 13a4.992 4.992 0 00-1.234-2.036A5 5 0 001.73 13h9.54zm1.555 0A6.5 6.5 0 000 14.5h13a6.5 6.5 0 00-.175-1.5z\" fill=\"#2C2D30\"/><path d=\"M13.333 9.333a.667.667 0 101.333 0V7.667h1.667a.667.667 0 100-1.333h-1.666V4.666a.667.667 0 00-1.333 0v1.666h-1.667a.667.667 0 100 1.333h1.666v1.667z\" fill=\"#2C2D30\"/>",
    "attrs": {
      "width": "17",
      "height": "15",
      "viewBox": "0 0 17 15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});