define('ember-poppays/components/empty-result', ['exports', 'ember-poppays/templates/components/empty-result'], function (exports, _emptyResult) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emptyResult.default,
    classNames: ['empty-result'],

    'data-test-empty-result': true
  });
});