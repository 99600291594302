define("ember-svg-jar/inlined/icon-gift-code", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Gift</title><path d=\"M18 10h-4V8h4v2zm-1 8h-3v-6h3v6zM13.293 2.294A.993.993 0 0114 2.001a1 1 0 01.706 1.706c-.59.591-1.649.935-2.532 1.119.184-.883.529-1.942 1.119-2.532zM12 10H8V8h4v2zm0 8H8v-6h4v6zM5.293 3.707A.993.993 0 015 3c0-.266.104-.518.294-.707A.99.99 0 016 2.001c.267 0 .518.104.707.293.59.589.934 1.648 1.118 2.53-.88-.185-1.945-.53-2.532-1.117zM6 10H2V8h4v2zm0 8H3v-6h3v6zM18 6h-3.115a4.96 4.96 0 001.236-.879 3.002 3.002 0 000-4.242c-1.134-1.132-3.108-1.132-4.242 0C10.243 2.515 10.02 5.597 10 5.943c-.02-.346-.243-3.428-1.879-5.064-1.134-1.132-3.11-1.132-4.242 0A2.98 2.98 0 003 3c0 .801.312 1.554.878 2.121A4.99 4.99 0 005.115 6H2C.897 6 0 6.897 0 8v3a1 1 0 001 1v6c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2v-6a1 1 0 001-1V8c0-1.103-.897-2-2-2z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});