define("ember-svg-jar/inlined/icon-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Actions/Link Blue</title><g fill=\"#3894FD\" fill-rule=\"evenodd\"><path d=\"M3.514 16.227l1.414-1.414-.707-.707a1 1 0 010-1.413l8.486-8.486a.996.996 0 011.414 0l.706.707 1.415-1.413-.707-.708c-1.133-1.133-3.11-1.133-4.243 0l-8.485 8.485a3.002 3.002 0 000 4.242l.707.707zM21.192 8.451l-.707-.707L19.07 9.16l.708.707a.99.99 0 01.292.707.993.993 0 01-.292.705l-8.485 8.486a1.027 1.027 0 01-1.415 0l-.707-.707-1.414 1.414.707.707a2.98 2.98 0 002.12.88 2.98 2.98 0 002.123-.88l8.485-8.486a2.98 2.98 0 00.878-2.119c0-.802-.312-1.556-.878-2.122M14.12 11.279l-1.413-1.414 7.07-7.071 1.415 1.414zM4.221 21.178l-1.414-1.414 7.071-7.071 1.414 1.414z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});