define("ember-svg-jar/inlined/phone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>44728103-A9DE-4439-BBDB-1DCC58AAB81C</title><defs><mask id=\"c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"190\" height=\"392.372\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><mask id=\"d\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"168.889\" height=\"303.116\" fill=\"#fff\"><use xlink:href=\"#b\"/></mask><rect id=\"a\" x=\"0\" y=\"0\" width=\"190\" height=\"392.372\" rx=\"24\"/><path id=\"b\" d=\"M11.014 40.651h168.889v303.116H11.014z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><use stroke=\"#D9D9D9\" mask=\"url(#c)\" stroke-width=\"4\" fill=\"#FFF\" xlink:href=\"#a\"/><use stroke=\"#F0F0F0\" mask=\"url(#d)\" stroke-width=\"2\" xlink:href=\"#b\"/><path fill=\"#F0F0F0\" d=\"M78.019 19.442h33.961v2.651H78.019z\"/></g>",
    "attrs": {
      "width": "190",
      "height": "393",
      "viewBox": "0 0 190 393",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});