define("ember-svg-jar/inlined/icon-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-warning</title><defs><path id=\"a\" d=\"M0 31.667h32.656V0H0v31.667z\"/></defs><g transform=\"matrix(1 0 0 -1 -1 30.667)\" fill=\"none\" fill-rule=\"evenodd\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><g mask=\"url(#b)\"><path d=\"M3.14 1.667c-1.583 0-2.281 1.115-1.552 2.477L15.002 29.19c.728 1.363 1.923 1.363 2.652 0L31.068 4.144c.73-1.362.032-2.477-1.552-2.477H3.14z\" fill=\"#07C98F\"/><path d=\"M14.41 21.95c0 1.085.827 1.757 1.919 1.757 1.066 0 1.919-.698 1.919-1.757v-9.948c0-1.06-.853-1.757-1.919-1.757-1.092 0-1.918.672-1.918 1.757v9.948zm-.16-15.244c0 1.11.933 2.015 2.079 2.015 1.146 0 2.079-.904 2.079-2.015 0-1.112-.933-2.016-2.079-2.016-1.146 0-2.079.904-2.079 2.016z\" fill=\"#FFF\"/></g></g>",
    "attrs": {
      "width": "31",
      "height": "29",
      "viewBox": "0 0 31 29",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});