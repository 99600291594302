define("ember-svg-jar/inlined/icon-processing-video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>1050DC11-25B0-46D4-B164-E9501DC39D9B</title><g fill=\"#974FEF\" fill-rule=\"evenodd\"><path d=\"M11 32l13-7.5L11 17z\"/><path d=\"M3.667 3.636H22v7.273h7.333v7.286H33V8.338L24.592 0H3.667C1.645 0 0 1.63 0 3.636v32.728C0 38.369 1.645 40 3.667 40h14.666v-3.636H3.667V3.636zM33 40.333c-1.974 0-3.784-.806-5.152-2.181l3.319-3.319H22V44l3.229-3.228A10.884 10.884 0 0033 44c6.066 0 11-4.934 11-11h-3.667A7.34 7.34 0 0133 40.333\"/><path d=\"M40.773 25.229A10.89 10.89 0 0033 22c-6.065 0-11 4.935-11 11h3.667c0-4.043 3.29-7.333 7.333-7.333 1.976 0 3.786.808 5.154 2.18l-3.32 3.32H44V22l-3.227 3.229z\"/></g>",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});