define("ember-svg-jar/inlined/icon-social-tip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>663260E7-0298-49B3-AA43-3E6823E5FD95</title><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#404040\" stroke-width=\"2\" cx=\"12.6\" cy=\"3\" r=\"3\"/><path d=\"M17.275 9.706a4.802 4.802 0 00-9.328-.09\" stroke=\"#404040\" stroke-width=\"2\"/><circle stroke=\"#404040\" stroke-width=\"2\" cx=\"5.4\" cy=\"16.2\" r=\"3\"/><path d=\"M10.075 22.906a4.802 4.802 0 00-9.328-.09\" stroke=\"#404040\" stroke-width=\"2\"/><path d=\"M19.74 15.87c-2.59 0-2.87 2.005-2.87 2.866 0 2.494 3.698 5.518 5.739 6.903 2.04-1.385 5.739-4.41 5.739-6.903 0-.86-.28-2.866-2.87-2.866-.644 0-1.435.79-1.435 1.431a1.435 1.435 0 01-2.87 0c0-.64-.79-1.431-1.434-1.431m2.87 12.91c-.256 0-.511-.068-.74-.204-.804-.484-7.869-4.867-7.869-9.84C14 15.306 16.306 13 19.74 13c1.054 0 2.082.476 2.869 1.212.786-.736 1.815-1.212 2.87-1.212 3.433 0 5.738 2.306 5.738 5.736 0 4.973-7.064 9.356-7.87 9.84a1.438 1.438 0 01-.738.204\" fill=\"#FF5151\"/></g>",
    "attrs": {
      "width": "33",
      "height": "30",
      "viewBox": "0 0 33 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});