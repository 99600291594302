define("ember-svg-jar/inlined/icon-reels", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.8 0h14.3c2.7 0 5.1 1.1 6.9 2.9 1.8 1.8 2.9 4.2 2.9 6.9v14.6c0 2.7-1.1 5.1-2.9 6.9-1.8 1.8-4.2 2.9-6.9 2.9H9.8c-2.7 0-5.1-1.1-6.9-2.9l-.1-.1C1.1 29.4 0 27 0 24.4V9.7C.1 7 1.2 4.6 3 2.9S7.1 0 9.8 0zm15.6 8.6h6c-.2-1.6-1-3-2.1-4.1-1.3-1.3-3.2-2.2-5.2-2.2h-2.5l3.8 6.3zm-2.7 0L19 2.4h-8.2l3.8 6.2h8.1zm-10.9 0l-3.6-6c-1.3.3-2.5 1-3.5 2-1.1 1-1.9 2.4-2.1 4h9.2zM31.5 11h-29v13.3c0 2 .8 3.8 2.1 5.1l.1.1c1.3 1.3 3.2 2.2 5.2 2.2h14.3c2 0 3.8-.8 5.2-2.1 1.3-1.3 2.1-3.2 2.1-5.2V11zm-17.3 4.2l7.4 4.8.3.3c.3.5.2 1.2-.3 1.5l-7.4 4.4c-.2.2-.4.2-.7.2-.6 0-1.1-.5-1.1-1.1V16c0-.2.1-.4.2-.6.4-.4 1.1-.5 1.6-.2z\" fill=\"#404040\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 34 34"
    }
  };
  _exports.default = _default;
});