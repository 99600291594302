define("ember-svg-jar/inlined/icon-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>045CADCD-2B98-47DF-9621-066CB64B6103</title><g fill=\"#D9D9D9\" fill-rule=\"evenodd\"><path d=\"M30.625 45H4.375C1.962 45 0 42.982 0 40.5V36h4.375v4.5h26.25V36H35v4.5c0 2.482-1.962 4.5-4.375 4.5M14.833 26.572h4.334V13.286c0-1.222.97-2.214 2.166-2.214h1.27L17 5.346l-5.603 5.726h1.27c1.196 0 2.166.992 2.166 2.214v13.286zm6.5 4.428h-8.666c-1.196 0-2.167-.992-2.167-2.214V15.5H6.167a2.167 2.167 0 01-2.002-1.366 2.25 2.25 0 01.47-2.413L15.468.649a2.131 2.131 0 013.064 0l10.833 11.072a2.25 2.25 0 01.47 2.413 2.167 2.167 0 01-2.002 1.366H23.5v13.286c0 1.222-.97 2.214-2.167 2.214z\"/></g>",
    "attrs": {
      "width": "35",
      "height": "45",
      "viewBox": "0 0 35 45",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});