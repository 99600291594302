define("ember-svg-jar/inlined/icon-twitter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style/><path d=\"M25 3.3v.4c0 4.2-3.2 9.1-9.1 9.1-1.7 0-3.4-.5-4.9-1.4h.8c1.4 0 2.8-.5 4-1.4-1.4 0-2.6-.9-3-2.2.5.1 1 .1 1.4-.1-1.5-.3-2.6-1.6-2.6-3.1.5.3 1 .4 1.5.4-1.4-.9-1.8-2.8-1-4.2 1.6 2 4 3.2 6.6 3.3-.3-1.1.1-2.3.9-3 1.3-1.2 3.3-1.1 4.5.1.7-.1 1.4-.4 2-.8-.2.7-.7 1.4-1.4 1.8.7-.1 1.3-.2 1.9-.5-.5.6-1 1.2-1.6 1.6z\" fill=\"#404040\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 27 13",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});