define("ember-svg-jar/inlined/status-in-progress-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/></defs><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#b)\"><defs><path id=\"c\" d=\"M0 0h15v15H0z\"/></defs><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#d)\" fill=\"#ff517c\" d=\"M-4.2-4.2h23.3v23.3H-4.2z\"/></g><g><defs><path id=\"e\" d=\"M11 7.2H8.1V3.3L4.2 8.2h2.9v3.9z\"/></defs><clipPath id=\"f\"><use xlink:href=\"#e\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#f)\"><defs><path id=\"g\" d=\"M0 0h15v15H0z\"/></defs><clipPath id=\"h\"><use xlink:href=\"#g\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#h)\" fill=\"#fff\" d=\"M0-.8h15.2v17.1H0z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});