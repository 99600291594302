define("ember-svg-jar/inlined/status-waiting-sm-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>1765D1E3-1D52-4B7D-A65C-F92B802FE99B</title><defs><path id=\"a\" d=\"M14.5 9.3h-3.9V4l-5.1 6.7h3.9V16z\"/></defs><use xlink:href=\"#a\" overflow=\"visible\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#404040\"/><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#b)\"><defs><path id=\"c\" d=\"M0-.5h19.7v20.4H0z\"/></defs><use xlink:href=\"#c\" overflow=\"visible\" fill=\"#404040\"/><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#d)\" fill=\"#404040\" d=\"M0-1.7h19.9v23.4H0z\"/></g><g><path d=\"M10 20C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z\" fill=\"#404040\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});