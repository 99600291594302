define("ember-svg-jar/inlined/icon-book", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>0D96BBBF-5135-42CE-A6D7-BB54E67BF0DC</title><path d=\"M15.063 12.719c0 .517-.435.776-.938.937 0 0-2.551.71-5.156 1.396V3.344l5.156-1.406c.518 0 .938.42.938.937v9.844h0zM8.03 15.062c-2.56-.689-5.156-1.406-5.156-1.406-.532-.146-.938-.42-.938-.937V2.875c0-.517.42-.938.938-.938l5.156 1.407v11.718h0zM14.125 1s-2.714.747-5.18 1.406c-.309.012-.62 0-.93 0C5.667 1.776 2.876 1 2.876 1 1.84 1 1 1.84 1 2.875v9.844c0 1.035.884 1.553 1.875 1.875 0 0 2.528.703 5.14 1.406h.95c2.634-.703 5.16-1.406 5.16-1.406.962-.25 1.875-.84 1.875-1.875V2.875C16 1.84 15.16 1 14.125 1h0z\" stroke=\"#FDC030\" stroke-width=\".3\" fill=\"#FDC030\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "17",
      "height": "17",
      "viewBox": "0 0 17 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});