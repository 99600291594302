define("ember-svg-jar/inlined/icon-close-popup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M15.35 1.949a.919.919 0 10-1.299-1.3L8 6.702 1.949.65a.919.919 0 10-1.3 1.299L6.702 8 .65 14.051a.919.919 0 001.299 1.3L8 9.298l6.051 6.051a.919.919 0 101.3-1.299L9.298 8l6.051-6.051z\" fill=\"#757575\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});