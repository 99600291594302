define("ember-svg-jar/inlined/icon-upload-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>97852E3A-9B2C-4E56-83EE-8C41BB6F9E64</title><g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M2.293 6.307l1.414 1.414 3.291-3.293v10.586h2V4.428l3.291 3.293 1.414-1.414L7.998.6zM14 17v2H2v-2H0v2c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-2h-2z\"/></g>",
    "attrs": {
      "width": "16",
      "height": "21",
      "viewBox": "0 0 16 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});