define('ember-poppays/components/profile-avatar', ['exports', 'ember-poppays/templates/components/profile-avatar'], function (exports, _profileAvatar) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _profileAvatar.default,
    classNames: ['profile-avatar'],
    classNameBindings: ['imageIsValid:profile-avatar--image:profile-avatar--letter', 'isExtraLarge:profile-avatar--extra-large', 'isExtraSmall:profile-avatar--extra-small', 'isExtraSmallPlus:profile-avatar--extra-small-plus', 'isLarge:profile-avatar--large', 'isMedium:profile-avatar--medium', 'isPendingInvite:profile-avatar--pending-invite', 'isSmall:profile-avatar--small'],

    avatarLetter: null,
    imageUrl: null,
    isExtraLarge: Ember.computed.equal('size', 'extra-large'),
    isExtraSmall: Ember.computed.equal('size', 'extra-small'),
    isExtraSmallPlus: Ember.computed.equal('size', 'extra-small-plus'),
    isLarge: Ember.computed.equal('size', 'large'),
    isMedium: Ember.computed.equal('size', 'medium'),
    isPendingInvite: false,
    isSmall: Ember.computed.equal('size', 'small'),
    size: 'medium',

    imageIsValid: Ember.computed('imageUrl', function () {
      var imageUrl = this.imageUrl;
      if (imageUrl) {
        return true;
      }
      return false;
    }),

    actions: {
      imageNotValid: function imageNotValid() {
        if (this.isDestroyed) {
          return;
        }
        this.set('imageIsValid', false);
      }
    }
  });
});