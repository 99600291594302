define("ember-svg-jar/inlined/icon-arrow-purple", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#984fef\" d=\"M32.675 1.375l3.81 3.795-3.81 3.81L33.71 10l4.83-4.845-4.815-4.83z\"/><path d=\"M36.5 5h-35\" stroke=\"#984fef\" stroke-width=\"2\" stroke-linecap=\"square\"/></g>",
    "attrs": {
      "width": "39",
      "height": "10",
      "viewBox": "0 0 39 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});