define("ember-svg-jar/inlined/icon-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>70660BD3-943D-4FF4-868B-27109258B321</title><path d=\"M1.2 2.5l6.3 6.3c.1.1.3.2.5.2s.4-.1.5-.2l6.3-6.3c.3-.3.3-.7 0-1-.3-.3-.7-.3-1 0L8 7.3 2.2 1.5c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1z\" fill=\"#404040\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 10"
    }
  };
  _exports.default = _default;
});