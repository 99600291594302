define("ember-svg-jar/inlined/icon-videos", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M7.364 10.4L12.273 8l-4.91-2.4z\"/><path d=\"M1.636 14.4V1.6h14.728l.001 12.8H1.636zM16.364 0H1.636C.734 0 0 .717 0 1.6v12.8c0 .882.734 1.6 1.636 1.6h14.728c.902 0 1.636-.718 1.636-1.6V1.6c0-.883-.734-1.6-1.636-1.6z\"/><path d=\"M3.273 4h1.636V2.4H3.273zm4.909-1.6H6.545V4h1.637zm3.273 0H9.818V4h1.637zM13.09 4h1.637V2.4h-1.636zm-9.817 9.6h1.636V12H3.273zm3.272 0h1.637V12H6.545zm3.273 0h1.637V12H9.818zm3.272 0h1.637V12h-1.636z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "16",
      "viewBox": "0 0 18 16"
    }
  };
  _exports.default = _default;
});