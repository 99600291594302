define("ember-svg-jar/inlined/icon-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>5EB83B0B-57D1-4328-AEB2-0903A1D0ECD1</title><path d=\"M1.2 8.6c.3.3.7.3 1 0L8 2.8l5.8 5.8c.3.3.7.3 1 0s.3-.7 0-1L8.5 1.3c-.1-.1-.3-.2-.5-.2s-.4.1-.5.2L1.2 7.6c-.3.3-.3.7 0 1z\" fill=\"#404040\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 16 10"
    }
  };
  _exports.default = _default;
});