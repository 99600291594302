define("ember-svg-jar/inlined/icon-file-misc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>file-video-2</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-4-1h32v32H-4z\"/><path d=\"M17.885.339H2.667A2.67 2.67 0 000 3.005v24a2.67 2.67 0 002.667 2.667h18.666A2.67 2.67 0 0024 27.005V6.453L17.885.34zM2.667 27.005v-24H16V8.34h5.333l.002 18.666H2.667z\" fill=\"#757575\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "24",
      "height": "30",
      "viewBox": "0 0 24 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});