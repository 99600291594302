define("ember-svg-jar/inlined/icon-stories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined Shape</title><path d=\"M18.56 15.55h5.837v2.896H18.56v5.837h-2.895v-5.837H9.827V15.55h5.838V9.713h2.895v5.838zm7.686 15.748a1.442 1.442 0 01-2.001-.436 1.463 1.463 0 01.433-2.014 14.092 14.092 0 006.427-11.85c0-7.778-6.265-14.084-13.993-14.084-.8 0-1.447-.652-1.447-1.457S16.313 0 17.112 0C26.44 0 34 7.61 34 16.998a17.01 17.01 0 01-7.754 14.3zM9.7 32.289a1.452 1.452 0 01-.662-1.944 1.452 1.452 0 011.94-.675 13.865 13.865 0 008.935 1.119 1.441 1.441 0 011.707 1.13 1.463 1.463 0 01-1.134 1.72A16.748 16.748 0 019.7 32.289zM.833 21.535a1.458 1.458 0 011.01-1.792c.77-.215 1.567.24 1.78 1.016a14.092 14.092 0 003.743 6.345c.574.561.588 1.483.03 2.06a1.44 1.44 0 01-2.046.031 17.01 17.01 0 01-4.517-7.66zm.51-11.551a1.444 1.444 0 011.91-.742 1.46 1.46 0 01.736 1.923 14.051 14.051 0 00-1.103 4.08 1.45 1.45 0 01-1.603 1.281A1.455 1.455 0 01.01 14.913a16.98 16.98 0 011.332-4.93zm4.76-5.876a1.441 1.441 0 012.04.154c.522.61.454 1.53-.152 2.055-.25.216-.492.441-.726.675a1.44 1.44 0 01-2.047-.01 1.464 1.464 0 01.01-2.06c.282-.282.574-.553.875-.814z\" fill=\"#404040\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});