define("ember-svg-jar/inlined/icon-attachment-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>BF3D2393-A5EF-4FDF-A0EF-F646A9BA8B5E</title><path d=\"M20 12V2l10 10H20zm6 20H6v-4h20v4zM6 20h16v4H6v-4zM22.828 0H4C1.794 0 0 1.796 0 4v32c0 2.206 1.794 4 4 4h24c2.206 0 4-1.794 4-4V9.172L22.828 0z\" fill=\"#974FEF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "32",
      "height": "40",
      "viewBox": "0 0 32 40",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});