define("ember-svg-jar/inlined/icon-bank", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>44AC57C4-4137-4EF6-BF64-753527299474</title><path d=\"M1.553 11.052l.216-.65h9.464l.216.65H1.553zm1.698-6.5h1.3v4.55h-1.3v-4.55zm-1.3-1.499l4.55-1.707 4.55 1.707v.2h-9.1v-.2zm5.2 6.05h-1.3v-4.55h1.3v4.55zm2.6 0h-1.3v-4.55h1.3v4.55zm3.217 2.394l-.65-1.95a.651.651 0 00-.617-.445h-.65v-4.55h.65a.65.65 0 00.65-.65v-1.3a.651.651 0 00-.421-.609L6.73.043a.656.656 0 00-.457 0l-5.2 1.95a.651.651 0 00-.422.61v1.3c0 .358.292.65.65.65h.65v4.55h-.65a.65.65 0 00-.616.444l-.65 1.95a.65.65 0 00.616.855h11.7a.65.65 0 00.617-.855z\" fill=\"#A5A5A5\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "13",
      "height": "13",
      "viewBox": "0 0 13 13",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});