define("ember-svg-jar/inlined/icon-lock-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M8 0a5.333 5.333 0 015.333 5.334V8A2.667 2.667 0 0116 10.667v8a2.667 2.667 0 01-2.667 2.667H2.667A2.667 2.667 0 010 18.667v-8A2.667 2.667 0 012.667 8V5.334A5.333 5.333 0 018 0zm5.333 9.334H2.667c-.737 0-1.334.597-1.334 1.333v8c0 .737.597 1.333 1.334 1.333h10.666c.737 0 1.334-.596 1.334-1.333v-8c0-.736-.597-1.333-1.334-1.333zM8 1.334a4 4 0 00-4 4V8h8V5.334a4 4 0 00-4-4z\" fill=\"#A5A5A5\"/><path d=\"M8 0a5.333 5.333 0 015.333 5.334v4H2.667v-4A5.333 5.333 0 018 0zm4 8V5.334a4 4 0 00-8 0V8h8z\" fill=\"#A5A5A5\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8 12c.737 0 1.334.597 1.334 1.334V16a1.333 1.333 0 11-2.667 0v-2.666C6.667 12.597 7.264 12 8 12z\" fill=\"#A5A5A5\"/>",
    "attrs": {
      "width": "16",
      "height": "22",
      "viewBox": "0 0 16 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});