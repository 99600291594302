define("@ember-decorators/utils/-private/class-field-descriptor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isFieldDescriptor = isFieldDescriptor;
  _exports.isDescriptor = isDescriptor;

  function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

  function _iterableToArrayLimit(arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function isClassDescriptor(possibleDesc) {
    var _possibleDesc = _slicedToArray(possibleDesc, 1),
        target = _possibleDesc[0];

    return possibleDesc.length === 1 && typeof target === 'function' && 'prototype' in target && !target.__isComputedDecorator;
  }

  function isFieldDescriptor(possibleDesc) {
    var _possibleDesc2 = _slicedToArray(possibleDesc, 3),
        target = _possibleDesc2[0],
        key = _possibleDesc2[1],
        desc = _possibleDesc2[2];

    return possibleDesc.length === 3 && _typeof(target) === 'object' && target !== null && typeof key === 'string' && (_typeof(desc) === 'object' && desc !== null && 'enumerable' in desc && 'configurable' in desc || desc === undefined) // TS compatibility
    ;
  }

  function isDescriptor(possibleDesc) {
    return isFieldDescriptor(possibleDesc) || isClassDescriptor(possibleDesc);
  }
});