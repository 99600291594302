define('ember-poppays/mixins/classname-modifiers', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    classNameBindings: ['modifierClassNames'],
    separator: '--',
    modifiers: [],

    componentBaseClass: Ember.computed.readOnly('classNames.firstObject'),

    modifierClassNames: Ember.computed('componentBaseClass', 'separator', 'modifiers', function () {
      var _this = this;

      var modifiers = this.modifiers;
      var modifierClassNames = modifiers.map(function (modifier) {
        // falsy check
        if (!modifier) {
          return '';
        }

        return '' + _this.componentBaseClass + _this.separator + modifier;
      });

      return modifierClassNames.join(' ');
    })
  });
});