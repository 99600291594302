define("ember-svg-jar/inlined/icon-setting-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17.308 7.338v3.324h-1.881a6.564 6.564 0 01-.932 2.07l1.26 1.262-1.761 1.763-1.261-1.261a6.62 6.62 0 01-2.071.932v1.88H7.339v-1.88a6.638 6.638 0 01-2.072-.933l-1.26 1.262-1.763-1.763 1.261-1.261a6.565 6.565 0 01-.932-2.072H.693V7.338h1.88c.193-.749.508-1.448.932-2.07l-1.26-1.262 1.761-1.762 1.261 1.26a6.658 6.658 0 012.072-.932V.692h3.323v1.88a6.64 6.64 0 012.07.933l1.262-1.26 1.762 1.761-1.261 1.261a6.59 6.59 0 01.932 2.071h1.88zM4.015 9A4.99 4.99 0 009 13.985 4.99 4.99 0 0013.985 9 4.99 4.99 0 009 4.015 4.99 4.99 0 004.015 9z\" fill=\"#A5A5A5\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});