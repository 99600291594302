define("ember-svg-jar/inlined/icon-document", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>8EE965D5-90A5-4BFB-A78B-B52D39F706CB</title><path d=\"M19.216 16.005h-12a1 1 0 000 2h12a1 1 0 100-2zm0 6h-12a1 1 0 100 2h12a1 1 0 100-2zm1-14a2 2 0 01-2-2v-4l6 6h-4zm4 20a2 2 0 01-2 2h-18a2 2 0 01-2-2v-24a2 2 0 012-2h11.972c-.018 2.395.028 4 .028 4a4 4 0 004 4h4v18zm-6-28v.028c-.128 0-.662-.049-2-.028h-12a4 4 0 00-4 4v24a4 4 0 004 4h18a4 4 0 004-4v-20l-8-8z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "27",
      "height": "33",
      "viewBox": "0 0 27 33",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});