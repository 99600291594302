define("ember-svg-jar/inlined/thumbs-down", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#757575\" fill-rule=\"evenodd\" d=\"M19 10h-2V2h2v8zm-5-1a1 1 0 00-.919.605L10.341 16h-1.34v-4a1 1 0 00-1-1H2.197l1.636-9h6.974L15 3.677V9h-1zm6-9h-4a1 1 0 00-1 1v.522L11.372.071A1.012 1.012 0 0011 0H3c-.483 0-.897.346-.984.821l-2 11A1 1 0 001 13h6v4a1 1 0 001 1h3c.4 0 .762-.238.92-.606L14.66 11H15a1 1 0 001 1h4a1 1 0 001-1V1a1 1 0 00-1-1z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "18",
      "viewBox": "0 0 21 18"
    }
  };
  _exports.default = _default;
});