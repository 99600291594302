define("ember-svg-jar/inlined/icon-approve", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#07C98F\" fill-rule=\"evenodd\"><path d=\"M11 21c-4.962 0-9-4.037-9-9s4.038-9 9-9v2c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7h2c0 4.963-4.038 9-9 9\"/><path d=\"M11.977 15.652L7 10.676l1.768-1.768 3.023 3.023L19.917 2l1.934 1.583z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});