define("ember-svg-jar/inlined/icon-shipping", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Shipping</title><path d=\"M18 18H2V8h16v10zM5.535 2H9v4H2.869l2.666-4zm11.596 4H11V2h3.465l2.666 4zm2.865.981a.984.984 0 00-.068-.338c-.011-.029-.026-.053-.039-.081-.019-.038-.032-.08-.057-.117l-4-6A1.001 1.001 0 0015 0H5c-.334 0-.646.167-.832.445l-4 6c-.025.037-.038.079-.057.117-.013.028-.028.052-.039.081a.984.984 0 00-.068.338C.003 6.988 0 6.993 0 7v12a1 1 0 001 1h18a1 1 0 001-1V7c0-.007-.003-.012-.004-.019z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});