define("ember-svg-jar/inlined/icon-clock-orange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icons/clock</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"11\" cy=\"11\" r=\"11\"/><path d=\"M11 4c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7m0 16c-4.962 0-9-4.037-9-9s4.038-9 9-9 9 4.037 9 9-4.038 9-9 9\" fill=\"#FDC030\"/><path d=\"M13.293 14.707l-3-3A1 1 0 0110 11V6h2v4.586l2.707 2.707-1.414 1.414z\" fill=\"#FDC030\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});