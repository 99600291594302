define("ember-svg-jar/inlined/logo-p", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>7865BC22-4950-4291-B3C5-C4589B0DE2A0</title><g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M0 0v59.81h17.088v-8.545H8.544V8.544h8.544V0zM34.176 0v8.544h17.088v25.632H34.176v8.544H59.81V0z\"/></g>",
    "attrs": {
      "width": "60",
      "height": "60",
      "viewBox": "0 0 60 60",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});