define("ember-svg-jar/inlined/icon-check-mark-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>FE09A0C2-796A-48BB-92B3-98C5B60CB61C</title><g stroke=\"#D9D9D9\" stroke-width=\"2\" fill=\"none\" fill-rule=\"evenodd\" stroke-linecap=\"round\"><path d=\"M5.896 9.167l7.746-7.39M5.896 9.167L1.359 4.629\"/></g>",
    "attrs": {
      "width": "15",
      "height": "11",
      "viewBox": "0 0 15 11",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});