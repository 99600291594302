define("ember-svg-jar/inlined/icon-rich-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>font-size</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-6-7h34v34H-6z\"/><g fill=\"#404040\" fill-rule=\"nonzero\"><path d=\"M20.796 17.167L16.44 5.837a.918.918 0 00-1.712 0l-1.95 5.07.975 3.51h4.022l1.058 2.75h-1.415V19H22v-1.833h-1.204zm-6.699-4.584l1.486-3.863 1.486 3.863h-2.972zM8.216 1.338a.917.917 0 00-.86-.671c-.43-.02-.765.244-.893.627l-5.29 15.873H0V19h4.583v-1.833H3.106l.916-2.75h5.925l.763 2.75H9.167V19h4.583v-1.833h-1.137L8.216 1.337zM4.633 12.583l2.62-7.862 2.184 7.862H4.633z\"/></g></g>",
    "attrs": {
      "viewBox": "0 0 22 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});