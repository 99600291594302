define("ember-svg-jar/inlined/icon-edits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-edits</title><circle cx=\"9\" cy=\"9\" fill=\"#ff517c\" r=\"9\"/><path d=\"M4.6 13.5l1-3.2 2.1 2.1-3.1 1.1zM13 7.1L9.1 11 7 8.8 10.9 5c.6-.6 1.5-.6 2.1 0 .6.5.6 1.5 0 2.1z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 18 18"
    }
  };
  _exports.default = _default;
});