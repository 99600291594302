define("ember-svg-jar/inlined/icon-add-red", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Add</title><path d=\"M18 8h-8V0H8v8H0v2h8v8h2v-8h8z\" fill=\"#ff5151\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});