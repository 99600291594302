define("ember-svg-jar/inlined/icon-close-tooltip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M13.432 1.705A.804.804 0 0012.295.568L7 5.863 1.705.568A.804.804 0 00.568 1.705L5.863 7 .568 12.295a.804.804 0 001.137 1.137L7 8.137l5.295 5.295a.804.804 0 001.137-1.137L8.137 7l5.295-5.295z\" fill=\"#757575\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});