define("ember-svg-jar/inlined/icon-lightning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>1765D1E3-1D52-4B7D-A65C-F92B802FE99B</title><circle cx=\"10\" cy=\"10\" r=\"10\" fill=\"#a5a5a5\"/><defs><path id=\"a\" d=\"M14.5 9.3h-3.9V4l-5.1 6.7h3.9V16z\"/></defs><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#b)\"><defs><path id=\"c\" d=\"M0-.5h19.7v20.4H0z\"/></defs><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#d)\" fill=\"#fff\" d=\"M0-1.7h19.9v23.4H0z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});