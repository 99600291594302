define("ember-svg-jar/inlined/icon-bolty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>1F2B1834-5144-4832-94BB-8BE95F142049</title><g fill=\"none\" fill-rule=\"evenodd\"><path fill=\"#FF4600\" d=\"M27.196.378L0 46.56h27.196V69.65L54.39 23.47H27.196z\"/><g fill=\"#FFF\"><path d=\"M41.82 36.798c0-3.054-2.499-5.552-5.553-5.552s-5.552 2.498-5.552 5.552h3.553c0-1.1.9-1.999 2-1.999 1.099 0 1.998.9 1.998 2h3.554zM24.899 36.798c0-3.054-2.499-5.552-5.553-5.552s-5.552 2.498-5.552 5.552h3.553c0-1.1.899-1.999 2-1.999 1.099 0 1.999.9 1.999 2h3.553z\"/></g></g>",
    "attrs": {
      "width": "55",
      "height": "70",
      "viewBox": "0 0 55 70",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});