define("ember-svg-jar/inlined/icon-x-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>58110C34-445D-4B60-BE05-F0D043B1B96D</title><path d=\"M19.504.488a1.69 1.69 0 00-2.376 0l-7.166 7.17L2.9.587a1.669 1.669 0 00-2.358 0 1.678 1.678 0 000 2.367l7.063 7.053-7.113 7.12a1.692 1.692 0 000 2.384c.657.65 1.721.65 2.378 0l7.113-7.12 7.063 7.07a1.67 1.67 0 102.36-2.367l-7.065-7.053 7.164-7.17a1.692 1.692 0 000-2.384z\" fill=\"#a5a5a5\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});