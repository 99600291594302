define("ember-svg-jar/inlined/icon-right", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>0C69A667-455A-4D12-845E-7DB3ACDC19DE</title><path d=\"M1.5 1.2c.3-.3.7-.3 1 0l6.3 6.3c.1.1.2.3.2.5s-.1.4-.2.5l-6.3 6.3c-.3.3-.7.3-1 0-.3-.3-.3-.7 0-1L7.3 8 1.5 2.2c-.3-.3-.3-.7 0-1h0z\" fill=\"#a5a5a5\" stroke=\"#a5a5a5\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 10 16"
    }
  };
  _exports.default = _default;
});