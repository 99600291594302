define("ember-svg-jar/inlined/pinterest-glyph", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>08141AF7-399C-47AC-B893-4B77AA814CD2</title><path d=\"M0 9.708c0 4.113 2.559 7.627 6.17 9.04-.084-.767-.161-1.946.034-2.784.177-.757 1.139-4.826 1.139-4.826s-.29-.581-.29-1.441c0-1.35.782-2.358 1.756-2.358.829 0 1.229.622 1.229 1.368 0 .833-.53 2.078-.805 3.232-.228.966.485 1.754 1.438 1.754 1.725 0 3.052-1.82 3.052-4.445 0-2.325-1.67-3.95-4.055-3.95-2.763 0-4.384 2.072-4.384 4.213 0 .834.321 1.73.723 2.216a.29.29 0 01.067.278c-.074.307-.238.966-.27 1.101-.042.178-.14.215-.325.13-1.212-.565-1.97-2.337-1.97-3.761 0-3.063 2.225-5.875 6.415-5.875 3.367 0 5.985 2.4 5.985 5.607 0 3.346-2.11 6.039-5.038 6.039-.984 0-1.909-.511-2.225-1.115l-.605 2.308c-.22.843-.811 1.9-1.207 2.545a9.714 9.714 0 002.875.433c5.361 0 9.708-4.347 9.708-9.709C19.417 4.346 15.07 0 9.71 0 4.347 0 0 4.346 0 9.708z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});