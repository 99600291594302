define("ember-svg-jar/inlined/icon-instagram-relink", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>07EFEBD5-9779-4FA7-95DA-B7AB29F7C042</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-1-1h40v40H-1z\"/><path d=\"M31.983 20.13A9.666 9.666 0 0030.4 20h-.8c-.167 0-.333.004-.498.013.012-.855.015-2.028.015-4.013 0-4.272-.016-4.778-.093-6.465-.071-1.56-.332-2.407-.551-2.971a4.957 4.957 0 00-1.197-1.84 4.958 4.958 0 00-1.84-1.197c-.564-.219-1.41-.48-2.97-.55-1.688-.078-2.194-.094-6.466-.094s-4.778.016-6.465.093c-1.56.071-2.408.332-2.971.551-.747.29-1.28.637-1.84 1.197a4.957 4.957 0 00-1.197 1.84c-.219.564-.48 1.41-.55 2.97-.078 1.688-.094 2.194-.094 6.466s.016 4.778.093 6.465c.071 1.56.332 2.408.551 2.971.29.747.637 1.28 1.197 1.84s1.093.907 1.84 1.197c.563.219 1.41.48 2.97.55 1.688.078 2.194.094 6.466.094 1.985 0 3.158-.003 4.013-.015-.009.165-.013.331-.013.498v.8c0 .54.044 1.068.13 1.583-.879.013-2.083.017-4.13.017-4.345 0-4.89-.018-6.597-.096-1.703-.078-2.866-.348-3.884-.744a7.843 7.843 0 01-2.834-1.845A7.843 7.843 0 01.84 26.48C.444 25.463.174 24.3.096 22.597.018 20.89 0 20.345 0 16c0-4.345.018-4.89.096-6.597C.174 7.7.444 6.537.84 5.52a7.843 7.843 0 011.845-2.834A7.843 7.843 0 015.52.84C6.537.444 7.7.174 9.403.096 11.11.018 11.655 0 16 0c4.345 0 4.89.018 6.597.096C24.3.174 25.463.444 26.48.84a7.843 7.843 0 012.834 1.845A7.843 7.843 0 0131.16 5.52c.396 1.018.666 2.181.744 3.884C31.982 11.11 32 11.655 32 16c0 2.047-.004 3.251-.017 4.13zM25.121 7.724a1.92 1.92 0 11-3.84 0 1.92 1.92 0 013.84 0zm-9.166.015a8.216 8.216 0 110 16.432 8.216 8.216 0 010-16.432zm0 13.55a5.333 5.333 0 100-10.668 5.333 5.333 0 000 10.667z\" fill=\"#404040\"/><g fill=\"#FF517C\"><path d=\"M30 23.6a6.407 6.407 0 00-6.4 6.4c0 3.529 2.871 6.4 6.4 6.4 3.529 0 6.4-2.871 6.4-6.4 0-3.529-2.871-6.4-6.4-6.4M30 38c-4.411 0-8-3.588-8-8 0-4.411 3.589-8 8-8s8 3.589 8 8c0 4.412-3.589 8-8 8\"/><path d=\"M30.8 30.8h-1.6v-5.6h1.6zM31 33.2a1 1 0 11-2 0 1 1 0 012 0\"/></g></g>",
    "attrs": {
      "viewBox": "0 0 38 38",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});