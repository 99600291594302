define("ember-svg-jar/inlined/icon-account-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>08B66DC9-ED07-4DC9-A98B-41A69A083BAA</title><defs><filter x=\"-.3%\" y=\"0%\" width=\"100.6%\" height=\"100.1%\" filterUnits=\"objectBoundingBox\" id=\"b\"><feOffset dx=\"-1\" in=\"SourceAlpha\" result=\"shadowOffsetInner1\"/><feComposite in=\"shadowOffsetInner1\" in2=\"SourceAlpha\" operator=\"arithmetic\" k2=\"-1\" k3=\"1\" result=\"shadowInnerInner1\"/><feColorMatrix values=\"0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 0 0.850980392 0 0 0 1 0\" in=\"shadowInnerInner1\"/></filter><path id=\"a\" d=\"M0 0h172v1024H0z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(-24 -804)\"><use fill=\"#FFF\" xlink:href=\"#a\"/><use fill=\"#000\" filter=\"url(#b)\" xlink:href=\"#a\"/></g><path d=\"M-24-12h172v48H-24z\"/><path d=\"M0 0h24v24H0z\"/><path d=\"M5.023 16.856A8.454 8.454 0 013.632 13.5H1.5A.5.5 0 011 13v-2a.5.5 0 01.5-.5h2.132c.219-1.23.703-2.37 1.39-3.356L3.516 5.636a.5.5 0 010-.707l1.414-1.414a.5.5 0 01.707 0l1.508 1.508A8.454 8.454 0 0110.5 3.632V1.5A.5.5 0 0111 1h2a.5.5 0 01.5.5v2.132c1.23.219 2.37.703 3.356 1.39l1.508-1.507a.5.5 0 01.707 0l1.414 1.414a.5.5 0 010 .707l-1.508 1.508a8.454 8.454 0 011.391 3.356H22.5a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2.132a8.454 8.454 0 01-1.39 3.356l1.507 1.508a.5.5 0 010 .707l-1.414 1.414a.5.5 0 01-.707 0l-1.508-1.508a8.454 8.454 0 01-3.356 1.391V22.5a.5.5 0 01-.5.5h-2a.5.5 0 01-.5-.5v-2.132a8.454 8.454 0 01-3.356-1.39l-1.508 1.507a.5.5 0 01-.707 0l-1.414-1.414a.5.5 0 010-.707l1.508-1.508zm6.977.85a5.706 5.706 0 100-11.412 5.706 5.706 0 000 11.412z\" fill=\"#404040\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});