define("ember-svg-jar/inlined/icon-check-mark-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>CCB7817C-F81D-4EC1-B8CC-5715908F60BA</title><path d=\"M5.158 10.495l8.929-9.285a.714.714 0 00-1.03-.99l-8.38 8.714L1.26 4.896a.714.714 0 10-1.09.923l3.927 4.643c.131.154.321.252.523.252a.73.73 0 00.538-.219z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "15",
      "height": "11",
      "viewBox": "0 0 15 11",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});