define("ember-svg-jar/inlined/icon-paper-plane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>BDED64BD-1D14-4379-B730-7F7DB25BE97D</title><path d=\"M6.484 12.379s-1.267-2.441-1.62-3.242l6.514-6.169-4.894 9.41h0zM1.621 7.11l9.321-4.457-6.484 6.079L1.62 7.11h0zm10.942-6.078C12.095 1.26 0 7.11 0 7.11s4.074 2.456 4.053 2.432C4.046 9.534 6.484 14 6.484 14S12.867 1.64 12.97 1.437c.088-.254-.102-.532-.406-.405h0z\" stroke=\"#404040\" stroke-width=\".2\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});