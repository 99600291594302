define("ember-svg-jar/inlined/icon-story-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/Instagram Story Post To</title><g fill=\"none\" fill-rule=\"nonzero\"><path d=\"M0 0h28v24H0z\"/><g fill=\"#404040\"><path d=\"M20.34 11.77h2.914v1.458H20.34v2.913h-1.457v-2.913H15.97V11.77h2.914V8.857h1.457v2.914zm3.868 7.879a.728.728 0 11-.788-1.225 7.042 7.042 0 00-3.808-12.967.728.728 0 010-1.457 8.499 8.499 0 014.596 15.649zm-8.322.489a.728.728 0 01.639-1.31c1.414.69 2.983.88 4.492.572a.728.728 0 01.292 1.427 8.464 8.464 0 01-5.423-.69zm-4.467-5.37a.728.728 0 111.405-.389 7.037 7.037 0 001.883 3.173.728.728 0 01-1.015 1.046 8.494 8.494 0 01-2.273-3.83zm.257-5.776a.728.728 0 111.331.59 6.99 6.99 0 00-.555 2.04.728.728 0 11-1.447-.166 8.447 8.447 0 01.67-2.464zm2.395-2.938a.728.728 0 01.95 1.105c-.125.108-.247.22-.365.337a.728.728 0 01-1.025-1.035c.142-.14.289-.276.44-.407zM4.278 16l1.414 1.414 4.707-4.707L5.692 8 4.278 9.414l2.293 2.293H1v2h5.571z\"/></g></g>",
    "attrs": {
      "width": "28",
      "height": "24",
      "viewBox": "0 0 28 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});