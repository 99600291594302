define("ember-svg-jar/inlined/icon-info-green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icons/info</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"11\" cy=\"11\" r=\"11\"/><g fill=\"#07C98F\"><path d=\"M11 3.8c-3.97 0-7.2 3.23-7.2 7.2s3.23 7.2 7.2 7.2 7.2-3.23 7.2-7.2-3.23-7.2-7.2-7.2M11 20c-4.963 0-9-4.037-9-9s4.037-9 9-9 9 4.037 9 9-4.037 9-9 9\"/><path d=\"M12.333 13.286V9.857A.846.846 0 0011.5 9H9.833v1.714h.834v2.572H9V15h5v-1.714h-1.667zM12 7a1 1 0 11-2 0 1 1 0 012 0\"/></g></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});