define("ember-svg-jar/inlined/icon-archived", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"10.5\" cy=\"11.47\" r=\"10.5\" fill=\"#2C2D30\"/><rect x=\"4.5\" y=\"6.47\" width=\"11.483\" height=\"2.671\" rx=\"1.336\" stroke=\"#FCFCFD\"/><path d=\"M5.234 9.04H15.25v5.577a1.5 1.5 0 01-1.5 1.5H6.734a1.5 1.5 0 01-1.5-1.5V9.04z\" stroke=\"#FCFCFD\"/><path d=\"M8.773 11.11h3.304\" stroke=\"#FCFCFD\" stroke-linecap=\"round\"/>",
    "attrs": {
      "width": "21",
      "height": "22",
      "viewBox": "0 0 21 22",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});