define("ember-svg-jar/inlined/icon-alert-diamond", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>interface-alert-diamond</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-2-2h24v24H-2z\"/><g fill=\"#FDC030\" fill-rule=\"nonzero\"><path d=\"M10 20a.997.997 0 01-.707-.293l-9-9a.999.999 0 010-1.414l9-9a.999.999 0 011.414 0l9 9a.999.999 0 010 1.414l-9 9A.993.993 0 0110 20zM2.414 10L10 17.586 17.586 10 10 2.415 2.414 10z\"/><path d=\"M9 6h2v6H9z\"/><circle cx=\"10\" cy=\"14\" r=\"1.25\"/></g></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});