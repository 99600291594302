define("ember-svg-jar/inlined/icon-bang", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:#fff}</style><path d=\"M11 22C4.9 22 0 17.1 0 11S4.9 0 11 0s11 4.9 11 11-4.9 11-11 11z\" fill=\"#ff5151\"/><path class=\"st1\" d=\"M9.9 4.4h2.2v7.7H9.9z\"/><circle class=\"st1\" cx=\"11\" cy=\"15.4\" r=\"1.4\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 22 22",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});