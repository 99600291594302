define("ember-svg-jar/inlined/icon-full-brief", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>BC62F954-5395-4BDB-836A-8ACFBD58DA8B</title><defs><path id=\"a\" d=\"M7.5 3.968V.5H.217v6.936H7.5z\"/><path id=\"c\" d=\"M5.25 11H0V.5h10.5V11z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(3 -.5)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M.832 7.436a.625.625 0 01-.431-.17.585.585 0 01.001-.856l4.931-4.702-.938.006h-.002a.603.603 0 01-.608-.607A.61.61 0 014.392.5h2.492a.624.624 0 01.441.18.586.586 0 01.175.418v2.51a.61.61 0 01-.606.607h-.002a.603.603 0 01-.607-.607L6.278 2.48 1.263 7.267a.627.627 0 01-.431.17\" fill=\"#A5A5A5\" mask=\"url(#b)\"/></g><g transform=\"translate(0 -.5)\"><mask id=\"d\" fill=\"#fff\"><use xlink:href=\"#c\"/></mask><path d=\"M8.68 11H1.657A1.658 1.658 0 010 9.345V2.319C0 1.367.8.5 1.678.5h4.179v1.214h-4.18a.471.471 0 00-.463.465l-.012 7.166c0 .249.204.452.454.452l7.166-.011a.47.47 0 00.463-.465V5.143H10.5v4.178C10.5 10.2 9.633 11 8.68 11\" fill=\"#A5A5A5\" mask=\"url(#d)\"/></g></g>",
    "attrs": {
      "width": "11",
      "height": "11",
      "viewBox": "0 0 11 11",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});