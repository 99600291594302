define("ember-svg-jar/inlined/icon-social-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>FDC6C0C0-0975-415E-9FF1-F421A59E8AF4</title><g fill=\"none\" fill-rule=\"evenodd\" opacity=\".788\"><path fill-rule=\"nonzero\" d=\"M-1-5h28v24H-1z\"/><path d=\"M3.278 11l1.414 1.414 4.707-4.707L4.692 3 3.278 4.414l2.293 2.293H0v2h5.571z\" fill=\"#404040\" fill-rule=\"nonzero\"/><path d=\"M21.674 13.971l.35-3.148a2.033 2.033 0 001.312-.998l1.351.226a6.818 6.818 0 01-3.013 3.92m-6.795.142l.48-1.201c.028.001.053.009.081.009a2.038 2.038 0 002.036-2.002l2.483-.829c.19.245.432.442.713.58l-.44 3.965a6.762 6.762 0 01-5.353-.522M11.36 8.16c0-.312.028-.615.069-.916l2.249 2.624a2.02 2.02 0 00-.278 1.012c0 .61.273 1.15.697 1.524l-.362.907a6.78 6.78 0 01-2.375-5.151m1.354-4.055l2.74.782a2.034 2.034 0 002.026 1.913c.314 0 .609-.078.875-.204l1.369 1.37a2.026 2.026 0 00-.2.835l-2.483.828a2.029 2.029 0 00-1.6-.79c-.26 0-.505.054-.733.141l-2.832-3.303-.059.05a6.787 6.787 0 01.897-1.622m7.014-2.556l-1.373 1.375a2.019 2.019 0 00-.875-.204c-.685 0-1.288.34-1.658.858l-2.067-.59a6.767 6.767 0 014.405-1.628c.54 0 1.064.07 1.568.19m3.808 2.46l-1.614 2.826a2.052 2.052 0 00-.362-.036c-.315 0-.608.076-.875.204l-1.369-1.37c.127-.266.204-.56.204-.874 0-.315-.077-.609-.204-.875l1.827-1.827a6.83 6.83 0 012.393 1.953m1.396 4.701l-1.368-.228a2.022 2.022 0 00-.464-.969l1.242-2.173c.393.86.618 1.812.618 2.818 0 .186-.013.37-.028.552M18.16.001C13.66 0 10 3.66 10 8.16c0 4.498 3.66 8.16 8.16 8.16s8.16-3.662 8.16-8.16c0-4.5-3.66-8.16-8.16-8.16\" fill=\"#404040\"/></g>",
    "attrs": {
      "width": "27",
      "height": "17",
      "viewBox": "0 0 27 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});