define("ember-svg-jar/inlined/icon-upload-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>31352F20-9F68-4EA2-A860-C5ECA1D53F7E</title><path d=\"M21.243 10.627v-8.85l8.85 8.85h-8.85zM8.853 30.098v-14.16l14.16 7.085-14.16 7.075zM3.541.007a3.544 3.544 0 00-3.54 3.54v31.861a3.544 3.544 0 003.54 3.54h24.78a3.544 3.544 0 003.54-3.54V8.124L23.746.007H3.542zm31.862 35.405h3.54v-10.62h-3.54zm1.77 1.759a1.77 1.77 0 10.001 3.542 1.77 1.77 0 000-3.542\" fill=\"#FE4E50\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "39",
      "height": "41",
      "viewBox": "0 0 39 41",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});