define("ember-svg-jar/inlined/icon-print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Actions/Print</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M20 18h-1v-2H5v2H4v-7c0-.552.449-1 1-1v2h14v-2c.551 0 1 .448 1 1v7zm-3 4H7v-4h10v4zm5-11c0-1.654-1.346-3-3-3h-2v2H7V8H5c-1.654 0-3 1.346-3 3v9h3v4h14v-4h3v-9zM7 3h10v4h2V1H5v6h2z\"/><path d=\"M17 13a1 1 0 100 2 1 1 0 000-2\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});