define("ember-svg-jar/inlined/icon-dollar-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M145 312c-2 69 31 100 104 102 78 1 113-34 109-101-6-58-62-73-106-79-48-17-99-25-99-95 0-48 32-79 99-78 60 0 97 25 96 84\" fill=\"none\" stroke=\"#000\" stroke-width=\"40\"/><path d=\"M250 15v470\" stroke=\"#000\" stroke-width=\"30\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "500",
      "height": "500"
    }
  };
  _exports.default = _default;
});