define("ember-svg-jar/inlined/icon-check", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M11 22C4.9 22 0 17.1 0 11S4.9 0 11 0s11 4.9 11 11-4.9 11-11 11z\" fill=\"#07c98f\"/><path d=\"M10 14.6l-3.7-3.7 1.4-1.4 2.3 2.3 4.3-4.3 1.4 1.4-5.7 5.7z\" fill=\"#fff\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 22 22"
    }
  };
  _exports.default = _default;
});