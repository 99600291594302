define("ember-svg-jar/inlined/logo-youtube", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/YouTube</title><path d=\"M11.6 18.83v-8.7l7.5 4.4-7.5 4.3zm16.8-11.3c-.3-1.2-1.3-2.2-2.6-2.6-2.3-.6-11.3-.6-11.3-.6s-9.1 0-11.3.6c-1.3.4-2.3 1.3-2.6 2.6-.6 2.3-.6 7-.6 7s0 4.7.6 7c.3 1.2 1.3 2.2 2.6 2.6 2.3.6 11.3.6 11.3.6s9.1 0 11.3-.6c1.2-.3 2.2-1.3 2.6-2.6.6-2.3.6-7 .6-7s0-4.7-.6-7z\" fill=\"#404040\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 29 29"
    }
  };
  _exports.default = _default;
});