define("ember-svg-jar/inlined/icon-expand-white", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Expand White</title><defs><filter x=\"-12.5%\" y=\"-12.5%\" width=\"125%\" height=\"125%\" filterUnits=\"objectBoundingBox\" id=\"a\"><feOffset in=\"SourceAlpha\" result=\"shadowOffsetOuter1\"/><feGaussianBlur stdDeviation=\".75\" in=\"shadowOffsetOuter1\" result=\"shadowBlurOuter1\"/><feColorMatrix values=\"0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.23 0\" in=\"shadowBlurOuter1\" result=\"shadowMatrixOuter1\"/><feMerge><feMergeNode in=\"shadowMatrixOuter1\"/><feMergeNode in=\"SourceGraphic\"/></feMerge></filter></defs><g filter=\"url(#a)\" transform=\"translate(2 2)\" fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M7.997 16H2v-6h6l-.003 6zM8 8H2c-1.103 0-2 .897-2 2v6c0 1.103.897 2 2 2h6c1.103 0 2-.897 2-2v-6c0-1.103-.897-2-2-2zM2 2h5V0H2C.897 0 0 .897 0 2v4h2V2zM16 0h-5v2h5v5h2V2c0-1.103-.897-2-2-2M16 16h-4v2h4c1.103 0 2-.897 2-2v-5h-2v5z\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});