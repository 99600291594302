define("ember-svg-jar/inlined/icon-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 942 + Fill 943</title><g fill=\"#fff\" fill-rule=\"evenodd\"><path d=\"M11.053 15a1.58 1.58 0 001.579-1.579V8.684h-1.58v4.737H1.58V3.947h4.737V2.368H1.579A1.58 1.58 0 000 3.948v9.473C0 14.291.708 15 1.579 15h9.474z\"/><path d=\"M9.474 1.579h2.83L3.39 10.494l1.117 1.117 8.915-8.916v2.831H15V0H9.474z\"/></g>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});