define("ember-svg-jar/inlined/icon-search-orange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Icons/Search</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#FFF\" cx=\"11\" cy=\"11\" r=\"11\"/><path d=\"M11 4c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7m0 16c-4.962 0-9-4.037-9-9s4.038-9 9-9 9 4.037 9 9-4.038 9-9 9\" fill=\"#FDC030\"/><path fill=\"#FDC030\" fill-rule=\"nonzero\" d=\"M11.586 13l3.782 3.735 1.414-1.414L13 11.586z\"/><path d=\"M10.5 14a3.5 3.5 0 110-7 3.5 3.5 0 010 7zm0-2a1.5 1.5 0 100-3 1.5 1.5 0 000 3z\" fill=\"#FDC030\" fill-rule=\"nonzero\"/></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});