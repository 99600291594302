define("ember-svg-jar/inlined/back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>0234B94F-28FA-40AC-90E0-D4F852CC239A</title><path d=\"M8.484 1.206a.71.71 0 00-1 0L1.2 7.466a.69.69 0 00-.2.534.689.689 0 00.2.533l6.283 6.262a.708.708 0 001-1.001L2.666 8l5.818-5.798a.702.702 0 000-.996h0z\" stroke=\"#A5A5A5\" fill=\"#A5A5A5\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "10",
      "height": "16",
      "viewBox": "0 0 10 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});