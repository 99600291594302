define("ember-svg-jar/inlined/icon-external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>External White</title><g fill=\"#FFF\" fill-rule=\"evenodd\"><path d=\"M14 19c1.103 0 2-.897 2-2v-6h-2v6H2V5h6V3H2C.897 3 0 3.897 0 5v12c0 1.103.897 2 2 2h12z\"/><path d=\"M12 2h3.586L4.293 13.293l1.414 1.414L17 3.414V7h2V0h-7z\"/></g>",
    "attrs": {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});