define("ember-svg-jar/inlined/icon-file-pdf", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>file-office-pdf</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-2-1h32v32H-2z\"/><g fill=\"#DE2A2A\" fill-rule=\"nonzero\"><path d=\"M4.667 3H18v5.333h5.333v1.334H26V6.448L19.885.333H4.667A2.67 2.67 0 002 3v6.667h2.667V3zM23.333 27H4.667v-1.333H2V27a2.67 2.67 0 002.667 2.667h18.666A2.67 2.67 0 0026 27v-1.333h-2.667V27zM10 12.333V23h2.667A5.339 5.339 0 0018 17.667a5.339 5.339 0 00-5.333-5.334H10zm5.333 5.334a2.67 2.67 0 01-2.666 2.666V15a2.67 2.67 0 012.666 2.667zM27.333 15v-2.667H22A2.67 2.67 0 0019.333 15v8H22v-4h4v-2.667h-4V15h5.333zM3.333 20.333h1.334c2.205 0 4-1.794 4-4 0-2.205-1.795-4-4-4h-4V23h2.666v-2.667zm0-5.333h1.334C5.4 15 6 15.597 6 16.333s-.599 1.334-1.333 1.334H3.333V15z\"/></g></g>",
    "attrs": {
      "width": "28",
      "height": "30",
      "viewBox": "0 0 28 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});