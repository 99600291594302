define("@popularpays/ember-design-system/components/circle-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="circle-loader" data-size={{@size}}></div>
  
  */
  {
    "id": "dajl9zB+",
    "block": "[[[10,0],[14,0,\"circle-loader\"],[15,\"data-size\",[30,1]],[12],[13],[1,\"\\n\"]],[\"@size\"],false,[]]",
    "moduleName": "@popularpays/ember-design-system/components/circle-loader.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});