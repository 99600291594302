define("ember-svg-jar/inlined/icon-send-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M.02.835l1.52 6.584c.06.28.301.5.601.54l8.502.88c.24.02.24.381 0 .421l-8.502.82a.66.66 0 00-.6.52L.02 17.166c-.14.56.46 1.02.98.76L17.625 9.6a.7.7 0 000-1.24L1 .075c-.52-.26-1.12.2-.98.76z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});