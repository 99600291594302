define("ember-svg-jar/inlined/icon-instagram-photo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Post to Instagram Icon</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M18.27 1.49c2.208 0 2.47.009 3.342.048.806.037 1.244.172 1.536.285.386.15.661.33.95.619.29.29.47.565.62.95.112.292.247.73.284 1.536.04.872.048 1.134.048 3.342s-.008 2.47-.048 3.342c-.037.806-.172 1.244-.285 1.536-.15.386-.33.661-.618.95-.29.29-.565.47-.951.62-.292.112-.73.247-1.536.284-.872.04-1.134.048-3.342.048s-2.47-.008-3.342-.048c-.806-.037-1.244-.172-1.535-.285a2.562 2.562 0 01-.951-.618 2.562 2.562 0 01-.619-.951c-.113-.292-.248-.73-.285-1.536-.04-.872-.048-1.134-.048-3.342s.009-2.47.048-3.342c.037-.806.172-1.244.285-1.535.15-.386.33-.662.619-.951.29-.29.565-.469.95-.619.292-.113.73-.248 1.536-.285.872-.04 1.134-.048 3.342-.048zM14.86.05c-.88.04-1.481.18-2.007.384a4.054 4.054 0 00-1.465.954c-.46.46-.742.921-.954 1.465-.204.526-.344 1.127-.384 2.007-.04.882-.05 1.164-.05 3.41 0 2.246.01 2.528.05 3.41.04.88.18 1.481.384 2.007.212.544.494 1.005.954 1.465.46.46.921.743 1.465.954.526.205 1.127.344 2.007.384.882.04 1.164.05 3.41.05 2.246 0 2.528-.01 3.41-.05.88-.04 1.481-.18 2.007-.384a4.054 4.054 0 001.465-.954c.46-.46.743-.92.954-1.465.205-.526.344-1.127.384-2.007.04-.882.05-1.164.05-3.41 0-2.246-.01-2.528-.05-3.41-.04-.88-.18-1.481-.384-2.007a4.054 4.054 0 00-.954-1.465 4.054 4.054 0 00-1.465-.954C23.161.23 22.56.09 21.68.05 20.798.01 20.516 0 18.27 0c-2.246 0-2.528.01-3.41.05z\"/><path d=\"M18.247 4a4.247 4.247 0 100 8.494 4.247 4.247 0 000-8.494zm0 7.004a2.757 2.757 0 110-5.514 2.757 2.757 0 010 5.514zM22.985 3.992a.992.992 0 11-1.985 0 .992.992 0 011.985 0\"/><g><path d=\"M3.278 12l1.414 1.414 4.707-4.707L4.692 4 3.278 5.414l2.293 2.293H0v2h5.571z\"/></g></g>",
    "attrs": {
      "width": "27",
      "height": "17",
      "viewBox": "0 0 27 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});