define("ember-svg-jar/inlined/icon-instagram", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>39390E70-606F-4D63-B6AE-B32956443709</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M10 1.804c2.67 0 2.985.01 4.04.059.974.044 1.504.207 1.856.344.466.181.8.398 1.15.748.35.35.566.683.747 1.15.137.351.3.88.344 1.855.048 1.055.059 1.37.059 4.04s-.01 2.985-.059 4.04c-.044.974-.207 1.504-.344 1.856-.181.466-.398.8-.748 1.15-.35.35-.683.566-1.15.747-.351.137-.88.3-1.855.344-1.054.048-1.37.059-4.04.059s-2.986-.01-4.04-.059c-.974-.044-1.504-.207-1.856-.344a3.097 3.097 0 01-1.15-.748 3.097 3.097 0 01-.747-1.15c-.137-.351-.3-.88-.344-1.855-.048-1.055-.059-1.37-.059-4.04s.01-2.985.059-4.04c.044-.974.207-1.504.344-1.856.181-.466.398-.8.748-1.15.35-.35.683-.566 1.15-.747.351-.137.88-.3 1.855-.344 1.055-.048 1.37-.059 4.04-.059m0-1.8c-2.715 0-3.055.01-4.122.06-1.064.048-1.79.217-2.426.464A4.9 4.9 0 001.68 1.681a4.9 4.9 0 00-1.153 1.77C.281 4.089.112 4.815.063 5.879.015 6.945.003 7.285.003 10s.012 3.055.06 4.122c.049 1.064.218 1.79.465 2.426a4.9 4.9 0 001.153 1.771 4.9 4.9 0 001.77 1.153c.637.247 1.363.416 2.427.465 1.067.048 1.407.06 4.122.06s3.055-.012 4.122-.06c1.064-.049 1.79-.218 2.426-.465a4.9 4.9 0 001.771-1.153 4.9 4.9 0 001.153-1.77c.247-.637.416-1.363.465-2.427.048-1.067.06-1.407.06-4.122s-.012-3.055-.06-4.122c-.049-1.064-.218-1.79-.465-2.426a4.9 4.9 0 00-1.153-1.771 4.9 4.9 0 00-1.77-1.153c-.637-.247-1.363-.416-2.427-.465C13.055.015 12.715.003 10 .003\"/><path d=\"M10 4.866a5.134 5.134 0 100 10.268 5.134 5.134 0 000-10.268zm0 8.466a3.332 3.332 0 110-6.664 3.332 3.332 0 010 6.664zM16.536 4.664a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});