define("ember-svg-jar/inlined/icon-at-symbol-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>959A299B-351B-4F0F-BCD0-1A96C2D42125</title><path d=\"M13.43 5.814c0-1.734-.595-3.077-1.581-4.046C10.693.629 9.01 0 7.072 0c-2.091 0-3.842.731-5.1 1.938C.731 3.128 0 4.794 0 6.885c0 2.159.765 3.995 2.091 5.219 1.122 1.037 2.686 1.632 4.539 1.632 1.479 0 2.924-.187 4.284-.85v-1.241c-1.411.612-2.737.816-4.267.816-1.53 0-2.788-.544-3.689-1.462-.952-.986-1.513-2.38-1.513-3.961 0-1.87.646-3.383 1.802-4.403.952-.85 2.261-1.377 3.842-1.377 1.547 0 2.856.476 3.74 1.36.765.765 1.224 1.819 1.224 3.213 0 1.139-.476 3.349-1.955 3.349-.646 0-.731-.561-.697-1.071l.306-4.301H8.568l-.187.714c-.306-.561-1.003-.901-1.632-.901-2.023 0-3.128 1.581-3.128 3.485 0 1.853.918 3.247 2.669 3.247.833 0 1.7-.493 2.04-1.258.119.884.816 1.292 1.666 1.292 2.482 0 3.434-2.516 3.434-4.573zm-5.219.935c0 1.122-.357 2.363-1.7 2.363-1.122 0-1.445-1.071-1.445-2.006 0-1.02.391-2.312 1.615-2.312 1.173 0 1.53.935 1.53 1.955z\" fill=\"#A5A5A5\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});