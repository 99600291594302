define("ember-svg-jar/inlined/icon-lock", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>8D883362-F4CF-4E5D-A735-A8B1F4666138</title><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><rect stroke=\"#404040\" stroke-width=\"1.5\" x=\".857\" width=\"6\" height=\"8.571\" rx=\"3\"/><circle stroke=\"#404040\" stroke-width=\"1.5\" fill=\"#FFF\" cx=\"3.857\" cy=\"8.143\" r=\"3.857\"/><rect fill=\"#404040\" x=\"3.429\" y=\"6.857\" width=\".857\" height=\"2.571\" rx=\".429\"/></g>",
    "attrs": {
      "width": "10",
      "height": "14",
      "viewBox": "0 0 10 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});