define("ember-svg-jar/inlined/icon-bank-symbol", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-bank-off</title><g fill=\"#4A4A4A\" fill-rule=\"evenodd\"><path d=\"M1 2.003V15.76c0 .55.452 1.003 1 1.003h32c.555 0 1-.446 1-1.003V2.003C35 1.453 34.548 1 34 1H2c-.555 0-1 .446-1 1.003zm-1 0C0 .897.89 0 2 0h32c1.105 0 2 .905 2 2.003V15.76c0 1.106-.89 2.003-2 2.003H2c-1.105 0-2-.905-2-2.003V2.003z\"/><path d=\"M29.84 6.115a2.512 2.512 0 00-.54-.395 1.33 1.33 0 00-.62-.135c-.273 0-.487.068-.64.205-.153.137-.23.335-.23.595 0 .2.067.365.2.495s.302.25.505.36c.203.11.422.22.655.33.233.11.452.242.655.395.203.153.372.342.505.565.133.223.2.502.2.835 0 .48-.133.882-.4 1.205-.267.323-.65.532-1.15.625v1.03h-.8v-1a3.312 3.312 0 01-.925-.245 2.665 2.665 0 01-.805-.515l.52-.78c.233.187.47.337.71.45.24.113.497.17.77.17.327 0 .57-.075.73-.225.16-.15.24-.362.24-.635a.787.787 0 00-.2-.55 2.157 2.157 0 00-.505-.4 8.003 8.003 0 00-.655-.345 3.905 3.905 0 01-.655-.39 1.995 1.995 0 01-.505-.54c-.133-.21-.2-.472-.2-.785 0-.467.133-.855.4-1.165.267-.31.627-.505 1.08-.585v-1.02h.8v1c.32.04.595.132.825.275.23.143.438.312.625.505l-.59.67zM5.5 6.688h16.531v-1H5v1h.5zM5.5 11.188H14v-1H5v1h.5z\"/></g>",
    "attrs": {
      "width": "36",
      "height": "18",
      "viewBox": "0 0 36 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});