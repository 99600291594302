define("ember-svg-jar/inlined/icon-plus-circle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>9A24EFD8-81C2-46AE-A2E5-40403C6624D0</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M10 18c-4.411 0-8-3.588-8-8 0-4.41 3.589-8 8-8s8 3.59 8 8c0 4.412-3.589 8-8 8m0-18C4.486 0 0 4.487 0 10c0 5.515 4.486 10 10 10s10-4.485 10-10c0-5.513-4.486-10-10-10\"/><path d=\"M11 5H9v4H5v2h4v4h2v-4h4V9h-4z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});