define("ember-svg-jar/inlined/icon-help-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"8.751\" cy=\"8.751\" r=\"8.251\" stroke=\"#5292F5\"/><path d=\"M11.398 7.243c0-1.32-1.092-2.352-2.664-2.352-1.596 0-2.88.876-2.88 2.556h1.548c0-.78.516-1.2 1.272-1.2s1.164.48 1.164 1.008c0 .384-.24.66-.468.864-.564.516-1.26.828-1.488 1.884-.072.348-.06.816-.06 1.164H9.31v-.336c0-.9.516-1.152 1.14-1.68.552-.468.948-1.068.948-1.908zm-2.004 6.348v-1.68H7.726v1.68h1.668z\" fill=\"#5292F5\"/>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});