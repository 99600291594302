define("ember-svg-jar/inlined/icon-note-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-note-gray</title><path d=\"M12.8 4H3.2V2.4h9.6V4zm0 3.2H3.2V5.6h9.6v1.6zm-4 3.2H3.2V8.8h5.6v1.6zM14.4 0H1.6A1.6 1.6 0 000 1.6v9.6a1.6 1.6 0 001.6 1.6h.8V16l3.2-3.2h8.8a1.6 1.6 0 001.6-1.6V1.6A1.6 1.6 0 0014.4 0z\" fill=\"#D9D9D9\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});