define("ember-svg-jar/inlined/logo-pinterest-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>logo</title><path d=\"M7.5 0C3.4 0 0 3.4 0 7.5c0 3.2 2 5.9 4.8 7-.1-.6-.1-1.5 0-2.2.1-.6.9-3.7.9-3.7s-.3-.4-.3-1.1c0-1 .6-1.8 1.4-1.8.6 0 .9.5.9 1.1 0 .6-.4 1.6-.6 2.5-.1.7.4 1.3 1.1 1.3 1.3 0 2.4-1.4 2.4-3.4 0-1.8-1.3-3.1-3.1-3.1-2.1 0-3.4 1.6-3.4 3.3 0 .6.2 1.3.6 1.7.1.1.1.1.1.2-.2.2-.3.7-.3.8 0 .1-.1.2-.3.1-.9-.4-1.5-1.8-1.5-2.9 0-2.4 1.7-4.5 5-4.5 2.6 0 4.6 1.9 4.6 4.3 0 2.6-1.6 4.7-3.9 4.7-.8 0-1.5-.4-1.7-.9 0 0-.4 1.4-.5 1.8-.2.7-.6 1.5-.9 2 .7.2 1.4.3 2.2.3 4.1 0 7.5-3.4 7.5-7.5S11.6 0 7.5 0\" fill=\"#d4d4d4\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});