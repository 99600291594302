define("ember-svg-jar/inlined/icon-plus-sign", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Combined Shape</title><path d=\"M7 5V0H5v5H0v2h5v5h2V7h5V5H7z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "12",
      "height": "12",
      "viewBox": "0 0 12 12",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});