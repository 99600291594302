define("ember-svg-jar/inlined/status-completed-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><path d=\"M7.5 15a7.5 7.5 0 100-15 7.5 7.5 0 000 15z\" id=\"a\"/><path id=\"c\" d=\"M2.846 5.467L0 2.62l1.086-1.086 1.76 1.76L6.14 0l1.086 1.085z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path fill=\"#07C98F\" mask=\"url(#b)\" d=\"M-4.167 19.167h23.334V-4.167H-4.167z\"/></g><g transform=\"translate(4.167 5)\"><mask id=\"d\" fill=\"#fff\"><use xlink:href=\"#c\"/></mask><path fill=\"#FFF\" mask=\"url(#d)\" d=\"M-4.167 9.633h15.56v-13.8h-15.56z\"/></g></g>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});