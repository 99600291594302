define("ember-svg-jar/inlined/icon-brief", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Labels/Brief</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M4 18H2V2h2v16zm4 0H6V2h10v6h2V1a1 1 0 00-1-1H1a1 1 0 00-1 1v18a1 1 0 001 1h7v-2z\"/><path d=\"M17 12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5m0 12c-3.859 0-7-3.14-7-7s3.141-7 7-7 7 3.14 7 7-3.141 7-7 7\"/><path d=\"M18 21h-2v-4h2zM18 15a1 1 0 11-1.998.002A1 1 0 0118 15\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});