define("ember-svg-jar/inlined/icon-gigs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st2{fill:none;stroke:#404040;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10}</style><g id=\"Layer_1\"><path d=\"M18.7 20.2c-.1 1-1 1.8-2 1.8H7.3c-1 0-1.9-.8-2-1.8L4.2 9.8C4.1 8.8 4.8 8 5.8 8h12.5c1 0 1.7.8 1.6 1.8l-1.2 10.4z\" fill=\"none\" stroke=\"#404040\" stroke-width=\"2\" stroke-linejoin=\"round\" stroke-miterlimit=\"10\"/><path fill=\"none\" stroke=\"#404040\" stroke-width=\"1.952\" stroke-linecap=\"round\" stroke-miterlimit=\"10\" d=\"M6.1 5H18\"/><path class=\"st2\" d=\"M7.6 2h8.9M9.6 15.7h3M9.6 13h5\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});