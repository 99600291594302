define("ember-svg-jar/inlined/icon-processing-blog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>E6E14F89-7544-4E80-9B15-27AF286F7775</title><g fill=\"#974FEF\" fill-rule=\"evenodd\"><path d=\"M22 19.5H7.333v3.667H22zm-14.667 11h11v-3.667h-11z\"/><path d=\"M3.667 3.667H22V11h7.333v7.333H33V8.408L24.592 0H3.667A3.672 3.672 0 000 3.667v33a3.67 3.67 0 003.667 3.666h14.666v-3.666H3.667v-33zM33 40.333c-1.974 0-3.784-.806-5.152-2.18l3.319-3.32H22V44l3.229-3.228A10.884 10.884 0 0033 44c6.066 0 11-4.934 11-11h-3.667A7.34 7.34 0 0133 40.333\"/><path d=\"M40.773 25.228a10.89 10.89 0 00-7.774-3.229c-6.064 0-11 4.936-11 11h3.667c0-4.042 3.29-7.333 7.333-7.333 1.977 0 3.786.809 5.154 2.18l-3.32 3.32h9.166V22l-3.226 3.229z\"/></g>",
    "attrs": {
      "width": "44",
      "height": "44",
      "viewBox": "0 0 44 44",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});