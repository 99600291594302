define("ember-svg-jar/inlined/icon-request-edits", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>08B4C74D-E0CB-4A8F-95CD-BCCAC2507649</title><g fill=\"#757575\" fill-rule=\"evenodd\"><path d=\"M18.648 13c.22.643.352 1.31.352 2 0 3.86-3.589 7-8 7H3.293l1.327-1.43a1 1 0 00.021-1.338C3.567 18 3.001 16.537 3.001 15c0-3.563 3.06-6.506 7-6.939V6.046C4.952 6.499 1 10.341 1 15c0 1.735.54 3.392 1.571 4.836L.267 22.32A.999.999 0 001 24h10c5.514 0 10-4.038 10-9 0-.677-.094-1.347-.26-2h-2.092z\"/><path d=\"M20 5.587L18.414 4 20 2.414 21.586 4 20 5.587zm-4.512 4.51l-2.114.529.529-2.114L17 5.414 18.585 7l-3.097 3.097zM20.707.293a1 1 0 00-1.414 0l-7 7a1.001 1.001 0 00-.263.465l-1 4a1 1 0 001.213 1.213l4-1c.176-.044.336-.136.464-.263l7-7a.999.999 0 000-1.414l-3-3.001z\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});