define("ember-svg-jar/inlined/icon-bell-grey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M1.925 6.47a6.11 6.11 0 0112.15 0l.787 7.47a.236.236 0 01-.235.26H1.373a.236.236 0 01-.235-.26l.787-7.47z\" stroke=\"#A5A5A5\" stroke-width=\"2\"/><mask id=\"a\" fill=\"#fff\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.798 15.2a3.693 3.693 0 003.2 1.847 3.693 3.693 0 003.2-1.847h-6.4z\"/></mask><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M4.798 15.2a3.693 3.693 0 003.2 1.847 3.693 3.693 0 003.2-1.847h-6.4z\" fill=\"#A5A5A5\"/><path d=\"M4.798 15.2v-1H3.064l.868 1.5.866-.5zm6.4 0l.865.5.869-1.5h-1.734v1zm-3.2.847c-.996 0-1.867-.54-2.335-1.348l-1.73 1.002a4.693 4.693 0 004.065 2.346v-2zm2.334-1.348a2.693 2.693 0 01-2.334 1.348v2a4.693 4.693 0 004.065-2.346l-1.73-1.002zm.866-.499h-6.4v2h6.4v-2z\" fill=\"#A5A5A5\" mask=\"url(#a)\"/>",
    "attrs": {
      "width": "16",
      "height": "18",
      "viewBox": "0 0 16 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});