define("ember-svg-jar/inlined/icon-collaborations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>E662481F-806F-474B-B60A-C3F4B2591E2B</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-1-.999h24v24H-1z\"/><g fill=\"#404040\" fill-rule=\"nonzero\"><path d=\"M4.642 4.642A8.907 8.907 0 0111 2c4.963 0 9 4.038 9 9h2c0-6.065-4.935-11-11-11a10.89 10.89 0 00-7.771 3.229L1 1v6h6L4.642 4.642zM17.357 17.358A8.9 8.9 0 0111 20c-4.963 0-9-4.038-9-9H0c0 6.065 4.935 11 11 11 2.967 0 5.721-1.167 7.771-3.229L21 21v-6h-6l2.357 2.358z\"/><path d=\"M7 11c0 2.206 1.795 4 4 4s4-1.794 4-4-1.795-4-4-4-4 1.794-4 4zm6 0c0 1.103-.896 2-2 2s-2-.897-2-2 .896-2 2-2 2 .897 2 2z\"/></g></g>",
    "attrs": {
      "width": "22",
      "height": "22",
      "viewBox": "0 0 22 22",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});