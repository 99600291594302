define("ember-svg-jar/inlined/icon-campaigns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>4CA4880C-54F9-4049-840B-1BC65D7F2723</title><path d=\"M6.466 11.25h4.408c.623 0 1.125.504 1.125 1.125v5.793l5.535-6.918h-4.41A1.125 1.125 0 0112 10.125V4.332L6.466 11.25zm4.408 11.25a1.124 1.124 0 01-1.125-1.125V13.5H4.124a1.127 1.127 0 01-.877-1.828l9-11.25a1.124 1.124 0 012.002.703V9h5.625c.434 0 .827.248 1.015.638.187.389.134.851-.136 1.19l-9 11.25a1.123 1.123 0 01-.879.422z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});