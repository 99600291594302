define("ember-svg-jar/inlined/icon-file-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-img</title><g fill=\"#757575\" fill-rule=\"evenodd\"><path d=\"M2.667 25.219V2.523H16v5.043h5.333l.002 17.653H2.667zM17.885 0H2.667C1.196.001 0 1.132 0 2.523v22.696c0 1.39 1.196 2.521 2.667 2.521h18.666c1.471 0 2.667-1.13 2.667-2.521V5.784L17.885 0z\"/><path d=\"M12 18.916l-2.667-2.522-4 5.043h13.334l-4-7.565zM9.333 15.133c1.472 0 2.667-1.129 2.667-2.522 0-1.393-1.195-2.522-2.667-2.522-1.474 0-2.666 1.129-2.666 2.522 0 1.393 1.192 2.522 2.666 2.522\"/></g>",
    "attrs": {
      "width": "24",
      "height": "28",
      "viewBox": "0 0 24 28",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});