define("ember-svg-jar/inlined/icon-x-red", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>2EDE6EE4-AF24-4ADF-8A3C-D25AA1B7F479</title><path d=\"M8.736.22a.755.755 0 00-1.064 0l-3.21 3.226L1.3.264a.745.745 0 00-1.056 0 .757.757 0 000 1.066l3.163 3.174L.22 7.708a.764.764 0 000 1.073.756.756 0 001.065 0l3.186-3.204 3.164 3.181a.746.746 0 001.057 0 .757.757 0 000-1.065L5.527 4.519l3.21-3.227a.764.764 0 000-1.073z\" fill=\"#FF517C\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "9",
      "height": "9",
      "viewBox": "0 0 9 9",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});