define("ember-svg-jar/inlined/icon-back", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-back</title><path d=\"M6.484.206L.2 6.466a.69.69 0 00-.2.534.689.689 0 00.2.533l6.283 6.262a.708.708 0 001-1.001L1.666 7l5.818-5.798a.702.702 0 000-.996.71.71 0 00-1 0z\" fill-rule=\"nonzero\" fill=\"#404040\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});