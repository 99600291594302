define("ember-svg-jar/inlined/icon-arrow-dark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>arrow</title><path fill=\"#000\" d=\"M32.675 1.375l3.81 3.795-3.81 3.81L33.71 10l4.83-4.845-4.815-4.83z\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 39 10",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});