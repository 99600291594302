define("ember-svg-jar/inlined/status-in-progress-sm-active", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:#404040}</style><title>6E39614B-7529-4A47-8B04-4BD476519B96</title><path class=\"st0\" d=\"M10 20C4.5 20 0 15.5 0 10S4.5 0 10 0s10 4.5 10 10-4.5 10-10 10zm0-18c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8z\"/><path class=\"st0\" d=\"M9.2 14.9l-4.1-4 1.8-1.8 1.9 2L13 5.3l2 1.4z\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 20 20",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});