define("ember-svg-jar/inlined/help", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>2408F34D-C2AC-4920-A0E5-9CB6A53093AF</title><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><circle stroke=\"#404040\" stroke-width=\"2\" cx=\"11\" cy=\"11\" r=\"11\"/><path d=\"M14.262 8.594c0-1.54-1.274-2.744-3.108-2.744-1.862 0-3.36 1.022-3.36 2.982H9.6c0-.91.602-1.4 1.484-1.4s1.358.56 1.358 1.176c0 .448-.28.77-.546 1.008-.658.602-1.47.966-1.736 2.198-.084.406-.07.952-.07 1.358h1.736v-.392c0-1.05.602-1.344 1.33-1.96.644-.546 1.106-1.246 1.106-2.226zM11.924 16v-1.96H9.978V16h1.946z\" fill=\"#404040\"/></g>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});