define("ember-svg-jar/inlined/icon-check-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-check-progress</title><path d=\"M10.967 22.66c.394-.11.767-.32 1.078-.63L29.249 4.824a2.437 2.437 0 00.004-3.446 2.431 2.431 0 00-3.446.004l-15.99 15.99-5.356-5.358a2.438 2.438 0 00-3.438.004 2.425 2.425 0 00-.004 3.438l6.894 6.894a2.44 2.44 0 003.054.309z\" fill=\"#FFF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "30",
      "height": "24",
      "viewBox": "0 0 30 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});