define("ember-svg-jar/inlined/icon-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st1{fill:#fff}</style><title>Error blip</title><g id=\"Styles\"><g id=\"Patterns\" transform=\"translate(-1636 -284)\"><g id=\"Form_x2F_Input-_x28_Error_x29_\" transform=\"translate(1370 245)\"><g id=\"Error-blip\" transform=\"translate(266 39)\"><path id=\"bg\" fill=\"#ff5151\" d=\"M0 0h25v25H0z\"/></g></g></g></g><path class=\"st1\" d=\"M11.2 5h2.5v8.8h-2.5z\"/><circle class=\"st1\" cx=\"12.5\" cy=\"17.5\" r=\"1.6\"/>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 25 25",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});