define("ember-svg-jar/inlined/icon-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<style>.st0{fill:#404040}</style><title>CA4BF11E-6D25-4029-8F80-AE9320BD1152</title><g id=\"Assets\"><g id=\"UI-Symbols-Icons\" transform=\"translate(-825 -214)\"><g id=\"icon-application\" transform=\"translate(825 214)\"><path id=\"Fill-35\" class=\"st0\" d=\"M13.8 15.5H4.1v-13h6.5v3.2h3.2v9.8zM11.7.9H4.1c-.9 0-1.6.7-1.6 1.6v13c0 .9.7 1.6 1.6 1.6h9.7c.9 0 1.6-.7 1.6-1.6V4.6L11.7.9z\"/><path id=\"Fill-37\" class=\"st0\" d=\"M10.6 8.2H5.7v1.6h4.9z\"/><path id=\"Fill-39\" class=\"st0\" d=\"M5.7 13h6.5v-1.6H5.7z\"/></g></g></g>",
    "attrs": {
      "version": "1.1",
      "id": "Layer_1",
      "xmlns": "http://www.w3.org/2000/svg",
      "x": "0",
      "y": "0",
      "viewBox": "0 0 18 18",
      "xml:space": "preserve"
    }
  };
  _exports.default = _default;
});