define("ember-svg-jar/inlined/icon-profile-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>7FF75D88-6D2B-4169-8A6F-F78E56C44F64</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M17 4a1.001 1.001 0 010 2 1.001 1.001 0 010-2m0 4c1.654 0 3-1.345 3-3 0-1.653-1.346-3-3-3a2.993 2.993 0 00-2.815 2H0v2h14.185A2.993 2.993 0 0017 8M7 12a1.001 1.001 0 010-2 1.001 1.001 0 010 2m0-4a2.993 2.993 0 00-2.815 2H0v2h4.185A2.993 2.993 0 007 14a2.993 2.993 0 002.815-2H20v-2H9.815A2.993 2.993 0 007 8M17 18a1.001 1.001 0 010-2 1.001 1.001 0 010 2m0-4a2.993 2.993 0 00-2.815 2H0v2h14.185A2.993 2.993 0 0017 20c1.654 0 3-1.345 3-3 0-1.653-1.346-3-3-3\"/></g>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});