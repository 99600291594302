define("ember-svg-jar/inlined/icon-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Fill 245</title><path d=\"M2.2 12.1V3.497l8.217 5.136a1.102 1.102 0 001.166 0L19.8 3.497l.001 8.603H2.2zm15.524-9.9L11 6.403 4.276 2.2h13.448zM19.8 0H2.2C.987 0 0 .988 0 2.2v9.9c0 1.213.987 2.2 2.2 2.2h17.6c1.213 0 2.2-.987 2.2-2.2V2.2C22 .988 21.013 0 19.8 0z\" fill=\"#404040\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "22",
      "height": "15",
      "viewBox": "0 0 22 15",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});