define("ember-svg-jar/inlined/status-waiting-payment-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/></defs><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#b)\"><defs><path id=\"c\" d=\"M0 0h15v15H0z\"/></defs><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#d)\" fill=\"#a5a5a5\" d=\"M-4.2-4.2h23.3v23.3H-4.2z\"/></g><g><defs><path id=\"e\" d=\"M8.3 3.3H6.7v.9c-1 .2-1.7 1-1.7 2 0 1.1.9 2.1 2.1 2.1h.8c.2 0 .4.2.4.4s-.2.5-.4.5H5v1.7h1.7v.8h1.7v-.9c1-.2 1.7-1 1.7-2 0-1.1-.9-2.1-2.1-2.1h-.9c-.2 0-.4-.2-.4-.4s.2-.4.4-.4H10V4.2H8.3v-.9z\"/></defs><clipPath id=\"f\"><use xlink:href=\"#e\" overflow=\"visible\"/></clipPath><g clip-path=\"url(#f)\"><defs><path id=\"g\" d=\"M0 0h15v15H0z\"/></defs><clipPath id=\"h\"><use xlink:href=\"#g\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#h)\" fill=\"#fff\" d=\"M.8-.8h13.3v16.7H.8z\"/></g></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 15 15"
    }
  };
  _exports.default = _default;
});