define("ember-svg-jar/inlined/icon-declined", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>8D62E74D-198C-4630-B5BD-855317AF3C7C</title><g transform=\"translate(1 1)\" fill=\"none\" fill-rule=\"evenodd\"><path d=\"M14 8h0V3.577L10.508 0h-9.5A1 1 0 000 .993v16.014A1 1 0 001.007 18H7\" stroke=\"#404040\" stroke-width=\"2\"/><path fill=\"#404040\" d=\"M9 1h2.016L13 3.035V5H9z\"/><circle fill=\"#FFF\" cx=\"19\" cy=\"19\" r=\"9\"/><path d=\"M19 27c-4.411 0-8-3.588-8-8 0-4.41 3.589-8 8-8s8 3.59 8 8c0 4.412-3.589 8-8 8m0-18C13.486 9 9 13.486 9 19c0 5.515 4.486 10 10 10s10-4.485 10-10c0-5.514-4.486-10-10-10\" fill=\"#4CC8EF\"/><path fill=\"#4CC8EF\" d=\"M21.293 15.293L19 17.587l-2.293-2.293-1.414 1.413L17.586 19l-2.293 2.294 1.414 1.413L19 20.415l2.293 2.293 1.414-1.413L20.414 19l2.293-2.293z\"/><path fill=\"#404040\" d=\"M9 8.001H3v2h6zM3 14.001h6v-2H3z\"/></g>",
    "attrs": {
      "width": "30",
      "height": "30",
      "viewBox": "0 0 30 30",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});