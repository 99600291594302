define("ember-svg-jar/inlined/icon-conversations-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#757575\" fill-rule=\"evenodd\"><path d=\"M12 22H2a1 1 0 01-.733-1.68l2.306-2.484C2.541 16.392 2 14.735 2 13c0-4.962 4.486-9 10-9a10.8 10.8 0 013.306.522l-.612 1.905A8.784 8.784 0 0012 6c-4.411 0-8 3.14-8 7 0 1.537.568 3 1.642 4.232a1 1 0 01-.021 1.338L4.293 20H12c4.411 0 8-3.14 8-7h2c0 4.962-4.486 9-10 9m9-15h-2V1h2z\"/><path d=\"M21 9a1 1 0 11-2 0 1 1 0 012 0\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24"
    }
  };
  _exports.default = _default;
});