define("ember-svg-jar/inlined/icon-account-green", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>857C0F01-1868-4F07-891C-E428351CBD43</title><g transform=\"translate(2 1)\" fill=\"none\" stroke=\"#07c98f\" stroke-width=\"1.889\"><circle cx=\"8.1\" cy=\"4.3\" r=\"3.8\"/><path d=\"M14.3 17.5H1.7v-.9c0-3.3 2.8-6 6.3-6s6.3 2.7 6.3 6v.9z\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});