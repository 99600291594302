define("ember-svg-jar/inlined/icon-instagram-reels-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M3.8 12.5l1.4 1.4 4.7-4.7-4.7-4.7-1.4 1.4 2.3 2.3H.5v2h5.6l-2.3 2.3z\" fill=\"#404040\"/><path d=\"M21.9.5h-6.8c-1.3 0-2.4.5-3.3 1.3-.8.8-1.4 2-1.4 3.2v6.8c0 1.2.5 2.4 1.3 3.2.8.8 2 1.3 3.3 1.3h6.8c1.3 0 2.4-.5 3.2-1.3.8-.8 1.4-2 1.4-3.2V5.1c0-1.3-.5-2.4-1.4-3.2C24.3 1 23.2.5 21.9.5zm.6 4.1l-1.8-2.9h1.2c1 0 1.8.4 2.4 1 .5.5.9 1.2 1 1.9h-2.8zm-1.3 0l-1.8-2.9h-3.9l1.8 2.9h3.9zm-6.9-2.9L16 4.6h-4.3c.1-.7.5-1.4 1-1.9.4-.5 1-.8 1.6-1zm11.1 4H11.6v6.2c0 .9.4 1.8 1 2.4.6.6 1.5 1 2.4 1h6.8c1 0 1.8-.4 2.4-1 .6-.6 1-1.5 1-2.4V5.7zm-4.7 4.2l-3.5-2.3c-.2-.2-.5-.1-.7.1-.1.1-.1.2-.1.3v4.4c0 .3.2.5.5.5.1 0 .2 0 .3-.1l3.5-2.1c.2-.2.3-.5.1-.7 0 0-.1 0-.1-.1z\" fill-rule=\"evenodd\" clip-rule=\"evenodd\" fill=\"#404040\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 27 17"
    }
  };
  _exports.default = _default;
});