define("ember-svg-jar/inlined/icon-pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Post to Pinterest Icon</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M18.5 0a8.5 8.5 0 00-3.097 16.415c-.075-.673-.142-1.704.029-2.438.155-.664.997-4.225.997-4.225s-.255-.51-.255-1.262c0-1.182.685-2.064 1.538-2.064.726 0 1.076.544 1.076 1.197 0 .73-.464 1.82-.704 2.83-.2.846.424 1.536 1.258 1.536 1.511 0 2.673-1.593 2.673-3.893 0-2.035-1.463-3.458-3.55-3.458-2.42 0-3.839 1.814-3.839 3.69 0 .73.282 1.513.633 1.939.07.084.08.158.059.244l-.236.963c-.037.156-.124.189-.285.115-1.061-.495-1.725-2.047-1.725-3.294 0-2.68 1.948-5.143 5.616-5.143 2.948 0 5.24 2.101 5.24 4.909 0 2.93-1.847 5.287-4.41 5.287-.862 0-1.671-.448-1.948-.976 0 0-.427 1.623-.53 2.02-.192.739-.71 1.664-1.057 2.229.795.246 1.64.378 2.517.378A8.5 8.5 0 0018.5 0M3.278 12l1.414 1.414 4.707-4.707L4.692 4 3.278 5.414l2.293 2.293H0v2h5.571z\"/></g>",
    "attrs": {
      "width": "27",
      "height": "17",
      "viewBox": "0 0 27 17",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});