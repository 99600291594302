define("ember-svg-jar/inlined/icon-5k", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>0962B4FD-EED1-4C08-8BFD-3751646E6578</title><g transform=\"translate(0 1)\" stroke=\"#404040\" stroke-width=\"1.5\" fill=\"none\" fill-rule=\"evenodd\"><circle cx=\"10.5\" cy=\"2.5\" r=\"2.5\"/><path d=\"M14.396 8.088a4.002 4.002 0 00-7.773-.075\"/><circle cx=\"16.5\" cy=\"13.5\" r=\"2.5\"/><path d=\"M20.396 19.088a4.002 4.002 0 00-7.773-.075\"/><circle cx=\"4.5\" cy=\"13.5\" r=\"2.5\"/><path d=\"M8.396 19.088a4.002 4.002 0 00-7.773-.075\"/></g>",
    "attrs": {
      "width": "21",
      "height": "21",
      "viewBox": "0 0 21 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});