define("ember-svg-jar/inlined/icon-hint-blue", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-hint-blue</title><g fill=\"#3894FD\" fill-rule=\"nonzero\"><path d=\"M9 18c-4.963 0-9-4.037-9-9 0-4.962 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9zM9 1.8C5.03 1.8 1.8 5.03 1.8 9s3.23 7.2 7.2 7.2 7.2-3.23 7.2-7.2S12.97 1.8 9 1.8z\"/><path d=\"M9.9 11.7H8.1V9H9a1.801 1.801 0 000-3.6 1.8 1.8 0 00-1.8 1.8H5.4c0-1.985 1.615-3.6 3.6-3.6s3.6 1.615 3.6 3.6a3.61 3.61 0 01-2.7 3.487V11.7z\"/><circle cx=\"9\" cy=\"13.5\" r=\"1.125\"/></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});