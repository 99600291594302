define("ember-svg-jar/inlined/logo-tiktok-light-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Network/TikTok</title><path d=\"M20.384 5.133c1.065.525 2.104.685 2.616.63v4.093s-1.46-.06-2.545-.346c-1.511-.4-2.48-1.015-2.48-1.015s-.673-.44-.723-.47v8.454c0 .47-.126 1.646-.502 2.627-.492 1.28-1.255 2.126-1.396 2.296 0 0-.929 1.14-2.56 1.906-1.471.69-2.766.676-3.153.69 0 0-2.234.09-4.247-1.265l-.01-.01a8.46 8.46 0 01-.598-.675c-.642-.816-1.034-1.776-1.134-2.052v-.005c-.154-.455-.464-1.529-.45-2.59l.003-.131c-.045 1.11.291 2.256.447 2.731v.005c.1.27.492 1.236 1.134 2.052.186.235.387.46.598.675a8.445 8.445 0 01-1.803-1.68c-.637-.806-1.029-1.762-1.13-2.042V19c-.16-.475-.496-1.626-.446-2.737.08-1.956.743-3.161.919-3.462a8.101 8.101 0 011.782-2.176 7.485 7.485 0 012.119-1.25c.482-.2.984-.346 1.496-.44a7.488 7.488 0 012.34-.036v.93h-.006v3.242s-1.652-.545-2.977.42c-.924.716-1.416 1.412-1.561 2.657-.005.916.22 2.212 1.456 3.077.145.095.286.18.426.255.216.29.477.545.778.756a4.38 4.38 0 001.028.514l-.198-.073a4.64 4.64 0 01-.825-.441 3.256 3.256 0 01-.778-.75c1.03.544 1.948.534 3.088.08.863-.346 1.51-1.126 1.812-1.987.164-.463.183-.926.186-1.402V0h4.162c-.005 0-.05.395.055 1.006h-.005c.125.735.467 1.786 1.38 2.821.367.39.779.74 1.225 1.03.156.1.312.19.477.276z\" fill=\"#d4d4d4\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});