define("ember-svg-jar/inlined/icon-mail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>5AAB9C98-60DE-4D7C-988A-5352D3EEE2F1</title><path d=\"M16 11c0 .101-.02.197-.048.289L11.5 6.5 16 3v8h0zm-13.222.973L7.29 7.165 9 8.457l1.636-1.3 4.586 4.816c-.072.017-12.372.017-12.444 0h0zM2 11V3l4.5 3.5-4.452 4.789A.983.983 0 012 11h0zm13.5-9L9 7 2.5 2h13zM15 1H3a2 2 0 00-2 2v8a2 2 0 002 2h12a2 2 0 002-2V3a2 2 0 00-2-2h0z\" stroke=\"#4CC8EF\" stroke-width=\".2\" fill=\"#4CC8EF\" fill-rule=\"evenodd\"/>",
    "attrs": {
      "width": "18",
      "height": "14",
      "viewBox": "0 0 18 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});