define("ember-svg-jar/inlined/icon-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M5.498 1.502c-.578 0-1.049.466-1.056 1.043v.026a1.056 1.056 0 101.056-1.069zm0-1.5c-1.15 0-2.123.76-2.444 1.806H.804a.75.75 0 000 1.5h2.25a2.558 2.558 0 004.887 0h10.196a.75.75 0 000-1.5H7.941A2.558 2.558 0 005.498.002zM.053 12.67a.75.75 0 01.75-.75h4.418a2.558 2.558 0 014.887 0h8.03a.75.75 0 110 1.5h-8.03a2.557 2.557 0 01-4.887 0H.803a.75.75 0 01-.75-.75zm6.556.006a1.056 1.056 0 002.111.001v-.015a1.056 1.056 0 00-2.111.001V12.676zM.803 6.864a.75.75 0 100 1.5H11a2.558 2.558 0 004.887 0h2.251a.75.75 0 000-1.5h-2.25a2.557 2.557 0 00-4.888 0H.803zm13.695.742a1.056 1.056 0 100 .015v-.007-.008z\" fill=\"#404040\"/>",
    "attrs": {
      "width": "19",
      "height": "16",
      "viewBox": "0 0 19 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});