define("ember-svg-jar/inlined/icon-share", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>3495CAF2-5D63-4190-894A-B73CB32839B0</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M15 1.586L13.586 3l2 2H10C7.794 5 6 6.794 6 9v1h2V9c0-1.102.897-2 2-2h5.586l-2 2L15 10.414 19.414 6 15 1.586z\"/><path d=\"M11 14H2V2h11V0H0v16h13v-5h-2z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "16",
      "viewBox": "0 0 20 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});