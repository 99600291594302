define("ember-svg-jar/inlined/icon-logout-old", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>5290733C-18E5-48E6-AC90-82B885121016</title><defs><path id=\"a\" d=\"M16 20H5c-1.1 0-2-.9-2-2v-3h2v3h11V2H5v3H3V2c0-1.1.9-2 2-2h11c1.1 0 2 .9 2 2v16c0 1.1-.9 2-2 2\"/></defs><clipPath id=\"b\"><use xlink:href=\"#a\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#b)\" fill=\"#404040\" d=\"M-2-5h25v30H-2z\"/><g><defs><path id=\"c\" d=\"M9.7 5.3L8.3 6.7 10.6 9H2v2h8.6l-2.3 2.3 1.4 1.4 4.7-4.7z\"/></defs><clipPath id=\"d\"><use xlink:href=\"#c\" overflow=\"visible\"/></clipPath><path clip-path=\"url(#d)\" fill=\"#404040\" d=\"M-3 .3h22.4v19.4H-3z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink",
      "viewBox": "0 0 20 20"
    }
  };
  _exports.default = _default;
});