define("ember-svg-jar/inlined/icon-thumby-sleepy", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>57439A89-08E7-4BEF-A110-C0286A72CBFA</title><defs><path id=\"a\" d=\"M51.554.64H0v57.792h51.554V.639z\"/></defs><g fill=\"none\" fill-rule=\"evenodd\"><g transform=\"translate(0 16.272)\"><mask id=\"b\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><path d=\"M51.557 33.121a6.864 6.864 0 00-2.12-4.952 6.864 6.864 0 002.12-4.951c0-3.789-3.099-6.888-6.887-6.888H23.718c-.147 0-.293.014-.438.023l2.283-8.488c.382-4.028-3.27-7.233-7.25-7.226-3.982.006-6.055 2.634-7.228 7.25l-4.04 15.018c-.33.863-.623 1.8-.877 2.817L.148 49.041c-1.107 5.783 4.226 9.394 9.393 9.394h11.625c.565 0 1.116-.06 1.654-.155a5.77 5.77 0 001.31.155h18.22c3.184 0 5.792-2.606 5.792-5.792a5.757 5.757 0 00-1.464-3.829c1.985-.921 3.374-2.93 3.374-5.254 0-1.765-.803-3.35-2.059-4.415 2.12-1.177 3.564-3.44 3.564-6.024\" fill=\"#FF517C\" mask=\"url(#b)\"/></g><path d=\"M17.273 48.727a9.09 9.09 0 009.09 9.091h0a9.09 9.09 0 009.092-9.09\" stroke=\"#FFF\" stroke-width=\"3.5\" stroke-linecap=\"round\"/><path d=\"M107 20c1.187 0 2 .786 2 2 0 1.073-.983 2-2 2H96c-1.496 0-2-.814-2-2 0-.565.632-1.492 1-2l8-12h-7c-1.128 0-2-.843-2-2 0-1.073.929-2 2-2h11c1.639 0 2 1.012 2 2 0 .621-.635 1.52-1 2l-8 12h7z\" fill=\"#404040\"/><path d=\"M86 9c.633 0 1 .352 1 1 0 .573-.458 1-1 1h-6c-.798 0-1-.367-1-1 0-.301.337-.73.533-1L84 3h-4c-.602 0-1-.384-1-1 0-.573.428-1 1-1h6c.874 0 1 .473 1 1 0 .331-.338.877-.533 1.133L82 9h4z\" stroke=\"#404040\" fill=\"#404040\"/><path d=\"M78 29c.633 0 1 .353 1 1 0 .573-.458 1-1 1h-6c-.798 0-1-.367-1-1 0-.301.337-.729.533-1L76 23h-4c-.602 0-1-.384-1-1 0-.572.428-1 1-1h6c.874 0 1 .473 1 1 0 .331-.338.877-.533 1.133L74 29h4z\" stroke=\"#D9D9D9\" fill=\"#D9D9D9\"/></g>",
    "attrs": {
      "width": "109",
      "height": "75",
      "viewBox": "0 0 109 75",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});