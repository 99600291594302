define("ember-svg-jar/inlined/icon-form-incomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<circle cx=\"9.806\" cy=\"9.843\" r=\"9.806\" fill=\"#D9D9D9\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M8.266 14.523l-3.15-3.15 1.13-1.13 1.965 1.964 5.831-6.414 1.183 1.075-6.959 7.655z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});