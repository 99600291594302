define("ember-svg-jar/inlined/icon-forward", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>icon-forward</title><path d=\"M.516.206a.71.71 0 011 0L7.8 6.466a.69.69 0 01.2.534.689.689 0 01-.2.533l-6.283 6.262a.708.708 0 01-1-1.001L6.334 7 .516 1.202a.702.702 0 010-.996z\" fill-rule=\"nonzero\" fill=\"#404040\"/>",
    "attrs": {
      "width": "8",
      "height": "14",
      "viewBox": "0 0 8 14",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});