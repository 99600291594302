define("ember-svg-jar/inlined/icon-bid", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.112 7.682l7.419 7.199c.255.243.419.567.462.917.037.33-.078.66-.312.895-.46.46-1.275.395-1.818-.144L9.687 9.125l.353-.354-1.09-1.092-1.471 1.47.11.11a1.025 1.025 0 010 1.45l-.554.55a1.033 1.033 0 01-1.443 0L2.237 7.907A1.01 1.01 0 012 7.253c.004-.272.117-.531.312-.72l.547-.564a1.017 1.017 0 011.446 0l.113.113 3.651-3.668-.046-.047-.07-.076a.987.987 0 01-.293-.716c.003-.273.116-.533.313-.72L8.519.3a1.02 1.02 0 011.447 0l3.28 3.29a1.025 1.025 0 010 1.449l-.553.554a1.002 1.002 0 01-.728.275 1.033 1.033 0 01-.731-.297l-.107-.107-1.146 1.146-.312.329 1.093 1.092.35-.35zm-4.239.854L8.251 7.16l.9-.905 1.377-1.38-1.85-1.853-3.654 3.668 1.85 1.847zM1.161 14h7.675c.308 0 .603.125.82.347.219.222.342.523.344.838V16H0v-.815a1.2 1.2 0 01.34-.839c.218-.222.513-.347.82-.346z\" fill=\"#404040\"/>",
    "attrs": {
      "width": "19",
      "height": "17",
      "viewBox": "0 0 19 17",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});