define("ember-svg-jar/inlined/icon-photos-medium-gray", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#a5a5a5\" fill-rule=\"evenodd\"><path d=\"M9 11.702a2.703 2.703 0 01-2.7-2.7c0-1.49 1.211-2.7 2.7-2.7s2.7 1.21 2.7 2.7c0 1.488-1.211 2.7-2.7 2.7m0-7.2a4.505 4.505 0 00-4.5 4.5c0 2.48 2.019 4.5 4.5 4.5s4.5-2.02 4.5-4.5c0-2.482-2.019-4.5-4.5-4.5\"/><path d=\"M1.8 14.402v-9.9h4.082l1.8-2.7h2.636l1.8 2.7H16.2v9.9H1.8zm14.4-11.7h-3.118l-1.266-1.9a1.798 1.798 0 00-1.498-.8H7.683a1.8 1.8 0 00-1.498.8l-1.266 1.9H1.8c-.993 0-1.8.807-1.8 1.8v9.9c0 .991.807 1.8 1.8 1.8h14.4c.993 0 1.8-.809 1.8-1.8v-9.9c0-.993-.807-1.8-1.8-1.8z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "18",
      "height": "17",
      "viewBox": "0 0 18 17"
    }
  };
  _exports.default = _default;
});