define("ember-svg-jar/inlined/icon-back-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path d=\"M9.82.16L.98 9l8.84 8.84 1.2-1.2L3.39 9l7.63-7.64-1.2-1.2z\" fill=\"#1F1F24\"/>",
    "attrs": {
      "width": "12",
      "height": "18",
      "viewBox": "0 0 12 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});