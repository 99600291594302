define("ember-svg-jar/inlined/thumbs-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<path fill=\"#15C48E\" fill-rule=\"evenodd\" d=\"M16.166 16H9.193L5 14.323V9h1c.4 0 .762-.238.919-.606L9.66 2H11v4a1 1 0 001 1h5.802l-1.636 9zM3 16H1V8h2v8zM19.768 5.359A1.002 1.002 0 0019 5h-6V1a1 1 0 00-1-1H9a.999.999 0 00-.918.605L5.341 7H5a1 1 0 00-1-1H0a1 1 0 00-1 1v10a1 1 0 001 1h4a1 1 0 001-1v-.522l3.629 1.451c.118.047.244.071.371.071h8a1 1 0 00.984-.821l2-11a.997.997 0 00-.216-.82z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21",
      "height": "18",
      "viewBox": "0 0 21 18"
    }
  };
  _exports.default = _default;
});