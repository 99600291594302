define("ember-svg-jar/inlined/status-rejected-sm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<defs><circle id=\"a\" cx=\"7.5\" cy=\"7.5\" r=\"7.5\"/><mask id=\"b\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"15\" height=\"15\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\"><use stroke=\"#A5A5A5\" mask=\"url(#b)\" stroke-width=\"2\" xlink:href=\"#a\"/><path d=\"M8.252 7.497l2.593-2.593a.53.53 0 10-.75-.749L7.504 6.748 4.91 4.155a.53.53 0 10-.749.75l2.593 2.592-2.599 2.599a.53.53 0 10.75.749l2.598-2.599 2.593 2.593a.529.529 0 10.749-.75L8.252 7.498z\" fill=\"#A5A5A5\"/></g>",
    "attrs": {
      "width": "15",
      "height": "15",
      "viewBox": "0 0 15 15",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});