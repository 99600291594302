define("ember-svg-jar/inlined/icon-blogs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M7.8 4H3v1.6h4.8zm0 3H3v1.6h4.8zm4.8 3H3v1.6h9.6z\"/><path d=\"M14.398 14.4H1.6V1.6h12.8l-.002 12.8zM14.4 0H1.6C.718 0 0 .718 0 1.6v12.8c0 .882.718 1.6 1.6 1.6h12.8c.882 0 1.6-.718 1.6-1.6V1.6c0-.882-.718-1.6-1.6-1.6z\"/><path d=\"M13.2 4H10v4.8h3.2z\"/></g>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16"
    }
  };
  _exports.default = _default;
});