define("ember-svg-jar/inlined/icon-attachment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>attachment</title><g fill=\"none\" fill-rule=\"evenodd\"><path d=\"M-11-6h34v34h-34z\"/><path d=\"M5 21c-2.897 0-5-2.313-5-5.5V7c0-3.991 2.794-7 6.5-7S13 3.009 13 7v7h-2V7c0-2.897-1.893-5-4.5-5S2 4.103 2 7v8.5c0 1.743.928 3.5 3 3.5s3-1.757 3-3.5V8c0-.602-.146-2-1.5-2S5 7.398 5 8v7H3V8c0-2.393 1.407-4 3.5-4S10 5.607 10 8v7.5c0 3.187-2.103 5.5-5 5.5\" fill=\"#404040\"/></g>",
    "attrs": {
      "width": "13",
      "height": "21",
      "viewBox": "0 0 13 21",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});