define("ember-svg-jar/inlined/icon-assets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>Assets</title><g fill=\"#404040\" fill-rule=\"evenodd\"><path d=\"M18.002 16H8V2h6v4h4v10zM15.415 0H8C6.898 0 6 .898 6 2v14c0 1.103.896 2 2 2h10c1.102 0 2-.897 2-2V4.586L15.413 0z\"/><path d=\"M5 3H3v16c0 1.103.897 2 2 2h12v-2H5V3z\"/><path d=\"M2 6H0v16c0 1.103.897 2 2 2h12v-2H2V6z\"/></g>",
    "attrs": {
      "width": "20",
      "height": "24",
      "viewBox": "0 0 20 24",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
  _exports.default = _default;
});