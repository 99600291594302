define("ember-svg-jar/inlined/gigs-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "content": "<title>7C7B86D7-C3DF-4756-AAE7-8699F313BA06</title><defs><path id=\"a\" d=\"M6 6h20v20H6z\"/><path id=\"b\" d=\"M0 0h20v20H0z\"/><mask id=\"c\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"20\" height=\"20\" fill=\"#fff\"><use xlink:href=\"#a\"/></mask><mask id=\"d\" maskContentUnits=\"userSpaceOnUse\" maskUnits=\"objectBoundingBox\" x=\"0\" y=\"0\" width=\"20\" height=\"20\" fill=\"#fff\"><use xlink:href=\"#b\"/></mask></defs><g fill=\"none\" fill-rule=\"evenodd\" opacity=\".5\"><use stroke=\"#404040\" mask=\"url(#c)\" stroke-width=\"4\" fill=\"#FFF\" xlink:href=\"#a\"/><use stroke=\"#404040\" mask=\"url(#d)\" stroke-width=\"4\" fill=\"#FFF\" xlink:href=\"#b\"/><path fill=\"#404040\" d=\"M6 6h8v2H6zM6 10h5v2H6z\"/></g>",
    "attrs": {
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "xmlns": "http://www.w3.org/2000/svg",
      "xmlns:xlink": "http://www.w3.org/1999/xlink"
    }
  };
  _exports.default = _default;
});