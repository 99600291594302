define("@popularpays/ember-design-system/components/poppays-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="poppays-box {{@className}}" data-appearance={{@appearance}} ...attributes>
    {{yield}}
  </div>
  
  */
  {
    "id": "71vPLu8F",
    "block": "[[[11,0],[16,0,[29,[\"poppays-box \",[30,1]]]],[16,\"data-appearance\",[30,2]],[17,3],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@className\",\"@appearance\",\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "@popularpays/ember-design-system/components/poppays-box.hbs",
    "isStrictMode": false
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});